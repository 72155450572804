import moment from 'moment-timezone';
import { TimestampUnit } from './types';

enum TimeOffsetDirection {
  FROM_BROWSER,
  TO_BROWSER,
}

export default class DateTime {
  private static readonly NATURAL_FACTOR = 1;
  private static readonly MILLISECONDS_PER_SECOND = 1000;
  private static readonly ONE_DAY_MS = 86400000;
  private static readonly MIN_TIMESTAMP_MS = -8640000000000000 + DateTime.ONE_DAY_MS;
  private static readonly MAX_TIMESTAMP_MS = 8640000000000000 - DateTime.ONE_DAY_MS;
  public static readonly MIN_VALUE = DateTime.fromTimestampNumber(
    DateTime.MIN_TIMESTAMP_MS,
    TimestampUnit.MILLISECONDS
  );

  public static readonly MAX_VALUE = DateTime.fromTimestampNumber(
    DateTime.MAX_TIMESTAMP_MS,
    TimestampUnit.MILLISECONDS
  );

  private readonly isoUtcDateTime: string;

  private constructor(isoUtcDateTime: string) {
    this.isoUtcDateTime = isoUtcDateTime;
  }

  public static now(): DateTime {
    return DateTime.fromMoment(moment());
  }

  public static fromTimestampNumber(timestamp: number, unitOfTime: TimestampUnit): DateTime {
    const factor = unitOfTime === TimestampUnit.SECONDS ? DateTime.MILLISECONDS_PER_SECOND : DateTime.NATURAL_FACTOR;

    return DateTime.fromMoment(moment(timestamp * factor));
  }

  public static fromISOString(isoDateTime: string): DateTime {
    return DateTime.fromMoment(moment.utc(isoDateTime, moment.ISO_8601, true));
  }

  public static fromISOStringSafe(): undefined;
  public static fromISOStringSafe(isoDateTime: string): DateTime;
  public static fromISOStringSafe(isoDateTime?: string): DateTime | undefined;
  public static fromISOStringSafe(isoDateTime?: string): DateTime | undefined {
    return isoDateTime ? DateTime.fromISOString(isoDateTime) : undefined;
  }

  public static fromFormat(dateTime: string, format: string, strict?: boolean): DateTime {
    return DateTime.fromMoment(moment(dateTime, format, strict));
  }

  public static fromFormatUTC(dateTime: string, format: string, strict?: boolean): DateTime {
    return DateTime.fromMoment(moment.utc(dateTime, format, strict));
  }

  public static fromDate(date: Date): DateTime {
    return new DateTime(date.toISOString());
  }

  /**
   * This method exists because of the following issue on github: https://github.com/Hacker0x01/react-datepicker/issues/1787.
   * The library moment is not used any more by this library. The current time zone in which date/time are displayed
   * is the current browser one. In the BIMTrack Website, the user can set a different time zone in its profile. All
   * date/time values should be displayed regarding this time zone not the browser one.
   * First, this function converts this ISO_8601 date/time value (UTC) into browser local time.
   * Second, it converts this ISO_8601 date/time value (UTC) into user local time.
   * Third, it computes the difference between these two local times.
   * Fourth, it subtracts the difference to this date/time value before returning it.
   * Doing this, will prevent the date selection from jittering.
   * This function is the counterpart of the toLocalDateWithTimeOffset function. You must call this function
   * to convert back the browser date into a DateTime locally compatible with the user profile time zone.
   */
  public static fromLocalDateWithTimeOffset(date: Date): DateTime {
    return DateTime.fromMoment(DateTime.applyTimeOffset(date.toISOString(), TimeOffsetDirection.FROM_BROWSER));
  }

  public static isDateTime(object: unknown): boolean {
    return !!(object as DateTime)?.isoUtcDateTime;
  }

  public static getMonths(): string[] {
    return moment.months();
  }

  public static getMinimumDateTime(dateTimes: DateTime[]): DateTime {
    return DateTime.fromMoment(moment.min(this.toMomentArray(dateTimes)));
  }

  public static getMaximumDateTime(dateTimes: DateTime[]): DateTime {
    return DateTime.fromMoment(moment.max(this.toMomentArray(dateTimes)));
  }

  public getDate(): number {
    return this.toMoment().date();
  }

  public getMonth(): number {
    const ZERO_INDEXED_MONTH = this.toMoment().month();
    const FIRST_MONTH = 1;

    return ZERO_INDEXED_MONTH + FIRST_MONTH;
  }

  public getYear(): number {
    return this.toMoment().year();
  }

  public getAbsoluteDifferenceInDays(other: DateTime): number {
    return Math.abs(this.getDifferenceInDays(other));
  }

  public getDifferenceInMilliseconds(other: DateTime): number {
    return this.toTimestampAsNumber(TimestampUnit.MILLISECONDS) - other.toTimestampAsNumber(TimestampUnit.MILLISECONDS);
  }

  public static areEqual(first?: DateTime, second?: DateTime): boolean {
    return first?.isoUtcDateTime === second?.isoUtcDateTime;
  }

  public static revertDateToUTC(date: string): string {
    const dateMoment = moment(date);
    const offset = dateMoment.utcOffset();
    const adjustedStart = dateMoment.utcOffset(offset).format('YYYY-MM-DDTHH:mm:ss.SSS');
    return `${adjustedStart}Z`;
  }

  public toTimestampAsNumber(unitOfTime: TimestampUnit): number {
    return parseInt(this.toTimestampAsString(unitOfTime));
  }

  public toTimestampAsString(unitOfTime: TimestampUnit): string {
    return this.toMoment().format(unitOfTime);
  }

  public toISOString(): string {
    return this.isoUtcDateTime;
  }

  public toDate(): Date {
    return new Date(this.isoUtcDateTime);
  }

  /**
   * This method exists because of the following issue on github: https://github.com/Hacker0x01/react-datepicker/issues/1787.
   * The library moment is not used any more by this library. The current time zone in which date/time are displayed
   * is the current browser one. In the BIMTrack Website, the user can set a different time zone in its profile. All
   * date/time values should be displayed regarding this time zone not the browser one.
   * First, this function converts this ISO_8601 date/time value (UTC) into browser local time.
   * Second, it converts this ISO_8601 date/time value (UTC) into user local time.
   * Third, it computes the difference between these two local times.
   * Fourth, it adds the difference to this date/time value before returning it.
   * Doing this, will prevent the date selection from jittering
   * Imagine the following scenario:
   *  - User time zone is UTC-12.
   *  - Browser time zone UTC+12.
   *  - It is now 22:00:00 in UTC-12 time zone.
   *  - The user selects 26-07-2020 in the date picker.
   *  - Without this function, the selected date in the date picker would end up to be 27-06-2020 (22:00:00)!
   *    We don't want that, we want the date picker to display 26-07-2020 (22:00:00) in the UTC+12 time zone.
   */
  public toLocalDateWithTimeOffset(): Date {
    return DateTime.applyTimeOffset(this.isoUtcDateTime, TimeOffsetDirection.TO_BROWSER).toDate();
  }

  public format(format?: string): string;
  public format(format: string, timezone: string): string;
  public format(format?: string, timezone?: string): string {
    if (timezone) {
      return this.toMoment().tz(timezone).format(format);
    }

    return this.toMoment().format(format);
  }

  public formatWithLocale(format?: string, locale = 'en'): string {
    return this.toMoment().locale(locale).format(format);
  }

  public isSameDay(other: DateTime): boolean {
    return this.toMoment().isSame(other.toMoment(), 'day');
  }

  public isValid(): boolean {
    return this.toMoment().isValid();
  }

  public isBefore(other: DateTime): boolean {
    return this.toMoment().isBefore(other.toMoment());
  }

  public isBeforeInDays(other: DateTime): boolean {
    return this.toMoment().isBefore(other.toMoment(), 'day');
  }

  public isBeforeInMonths(other: DateTime): boolean {
    return this.toMoment().isBefore(other.toMoment(), 'month');
  }

  public isAfter(other: DateTime): boolean {
    return this.toMoment().isAfter(other.toMoment());
  }

  public isAfterInDays(other: DateTime): boolean {
    return this.toMoment().isAfter(other.toMoment(), 'day');
  }

  public isEqual(other: DateTime): boolean {
    return DateTime.areEqual(this, other);
  }

  public addMinutes(minutes: number): DateTime {
    return this.add(minutes, 'minutes');
  }

  public addHours(hours: number): DateTime {
    return this.add(hours, 'hours');
  }

  public subtractMinutes(minutes: number): DateTime {
    return this.subtract(minutes, 'minutes');
  }

  public subtractHours(hours: number): DateTime {
    return this.subtract(hours, 'hours');
  }

  public addDays(days: number): DateTime {
    return this.add(days, 'days');
  }

  public subtractDays(days: number): DateTime {
    return this.subtract(days, 'days');
  }

  public addMonths(months: number): DateTime {
    return this.add(months, 'month');
  }

  public subtractMonths(months: number): DateTime {
    return this.subtract(months, 'month');
  }

  public addYears(years: number): DateTime {
    return this.add(years, 'years');
  }

  public subtractYears(years: number): DateTime {
    return this.subtract(years, 'years');
  }

  public withTimeOf(otherDate: DateTime): DateTime {
    const sourceMoment = this.toMoment();
    const otherMoment = otherDate.toMoment();
    const hour = otherMoment.get('hour');
    const minutes = otherMoment.get('minute');
    const second = otherMoment.get('second');
    const millisecond = otherMoment.get('millisecond');
    const sourceMomentWithTimeOfOtherMoment = sourceMoment.set({
      hour,
      minutes,
      second,
      millisecond,
    });

    return DateTime.fromMoment(sourceMomentWithTimeOfOtherMoment);
  }

  public startOfDay(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).startOf('day'));
    }

    return DateTime.fromMoment(this.toMoment().startOf('day'));
  }

  public startOfWeek(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).startOf('week'));
    }

    return DateTime.fromMoment(this.toMoment().startOf('week'));
  }

  public startOfMonth(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).startOf('month'));
    }

    return DateTime.fromMoment(this.toMoment().startOf('month'));
  }

  public startOfYear(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).startOf('year'));
    }

    return DateTime.fromMoment(this.toMoment().startOf('year'));
  }

  public endOfDay(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).endOf('day'));
    }

    return DateTime.fromMoment(this.toMoment().endOf('day'));
  }

  public endOfWeek(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).endOf('week'));
    }

    return DateTime.fromMoment(this.toMoment().endOf('week'));
  }

  public endOfMonth(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).endOf('month'));
    }

    return DateTime.fromMoment(this.toMoment().endOf('month'));
  }

  public endOfYear(timezone?: string): DateTime {
    if (timezone) {
      return DateTime.fromMoment(this.toMoment().tz(timezone).endOf('year'));
    }

    return DateTime.fromMoment(this.toMoment().endOf('year'));
  }

  private add(timeUnits: moment.DurationInputArg1, timeUnitName: moment.DurationInputArg2): DateTime {
    return DateTime.fromMoment(this.toMoment().add(timeUnits, timeUnitName));
  }

  private subtract(timeUnits: moment.DurationInputArg1, timeUnitName: moment.DurationInputArg2): DateTime {
    return DateTime.fromMoment(this.toMoment().subtract(timeUnits, timeUnitName));
  }

  public getDifferenceInDays(other: DateTime, timezone?: string): number {
    if (timezone) {
      return this.toMoment().tz(timezone).diff(other.toMoment().tz(timezone), 'days', true);
    }

    return this.toMoment().diff(other.toMoment(), 'days', true);
  }

  private toMoment(): moment.Moment {
    return moment(this.isoUtcDateTime);
  }

  private static toMomentArray(dateTimes: DateTime[]): moment.Moment[] {
    return dateTimes.map(dateTime => dateTime.toMoment());
  }

  private static fromMoment(sourceMoment: moment.Moment): DateTime {
    return new DateTime(sourceMoment.toISOString());
  }

  private static applyTimeOffset(isoUtcDateTime: string, mode: TimeOffsetDirection): moment.Moment {
    const localTime = moment.tz(isoUtcDateTime, moment.tz.guess(true)).local(true);
    const userProfileTime = moment(isoUtcDateTime).local(true);
    const translationFactor = mode === TimeOffsetDirection.FROM_BROWSER ? 1 : -1;
    const deltaOffsetToApply = localTime.diff(userProfileTime) * translationFactor;
    const momentToTranslate = moment(isoUtcDateTime);

    momentToTranslate.add(deltaOffsetToApply, 'milliseconds');

    return momentToTranslate;
  }
}
