import { Common } from "@newforma/platform-client-api-sdk";
import { Request } from "../Request";
import { ITag } from "office-ui-fabric-react";
import { UploadFileMapping } from "../../mapping/UploadFileMapping";
import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";
import { IHub } from "../../models/Hub";
import { FileWithId } from "../../models/shared/FileWithId";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Logger } from "../Logger";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { OutlookApiService } from "../OutlookApiService";
import { BaseApiService, Method } from "./BaseApiService";
import { ProjectItemFeature } from "./types";

export class FileUploadApiService extends BaseApiService {
    private readonly maxChunkSize = 25;
    static readonly maxAllowedFile = 100;

    constructor(
        private logger: Logger,
        protected newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private outlookApiService: OutlookApiService
    ) {
        super(newformaApiClient);
    }

    async uploadFile(
        selectedHub: IHub,
        selectedProject: ITag,
        attachments: (Office.AttachmentDetails | FileWithId)[],
        projectItemFeature: ProjectItemFeature
    ): Promise<string[]> {
        this.logger.info("Upload files");
        // Get all attachmentDetailsMetadata
        const attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(attachments);

        if (!attachmentsDetails.length) {
            return Promise.resolve([]);
        }

        const uploadRequests = this.createChunks(attachmentsDetails)
            .map((x) => x.map(UploadFileMapping.toFileMetadata))
            .map(UploadFileMapping.toUploadRequest);

        const url = this.buildUploadSessionEndpoint(
            this.newformaApiClient.mapDomainRegion(selectedHub),
            selectedHub.key.toString(),
            selectedProject.key.toString(),
            projectItemFeature
        );

        const uploadResponses: Common.UploadResponse[] = [];

        for (const uploadRequest of uploadRequests) {
            uploadResponses.push(await this.createSession(uploadRequest, url));
        }

        // Upload file 1 by 1 to S3 bucket
        for (const uploadResponse of uploadResponses) {
            for (const fileLocationMetadata of uploadResponse.files) {
                const foundFile = attachmentsDetails.find(
                    (attachmentDetailsMetadata) =>
                        attachmentDetailsMetadata.fileName.toLowerCase() ===
                            fileLocationMetadata.fileName.toLowerCase() &&
                        attachmentDetailsMetadata.file.size === fileLocationMetadata.sizeInBytes
                );

                if (foundFile && fileLocationMetadata.uploadUrl) {
                    await this.uploadFileToS3(fileLocationMetadata.uploadUrl, foundFile);
                }
            }
        }

        return uploadResponses.map(({ id }) => id);
    }

    private createChunks(attachmentDetailsMetadata: AttachmentDetailsMetadata[]): AttachmentDetailsMetadata[][] {
        const attachmentDetailsMetadataChunks: AttachmentDetailsMetadata[][] = [];

        // Create chunk of 25
        for (let i = 0; i < attachmentDetailsMetadata.length; i += this.maxChunkSize) {
            attachmentDetailsMetadataChunks.push(attachmentDetailsMetadata.slice(i, i + this.maxChunkSize));
        }

        return attachmentDetailsMetadataChunks;
    }

    private createSession(uploadRequest: Common.UploadRequest, url: string): Promise<Common.UploadResponse> {
        this.logger.info("Create session");

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Post), body: JSON.stringify(uploadRequest) },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private uploadFileToS3(signedUploadUrl: string, fileDetailsMetadata: AttachmentDetailsMetadata): Promise<void> {
        this.logger.info(`Upload file ${fileDetailsMetadata.fileName} to S3`);

        return this.requestWrapper.put(signedUploadUrl, undefined, undefined, fileDetailsMetadata.file, {
            contentType: fileDetailsMetadata.file.type,
            processData: false,
        });
    }

    private buildUploadSessionEndpoint(
        domain: string,
        hubId: string,
        projectId: string,
        projectItemFeature: ProjectItemFeature
    ): string {
        return new URL(`${this.basePimUrl(domain, hubId, projectId, projectItemFeature)}/upload`).toString();
    }
}
