import { Common } from "@newforma/platform-client-api-sdk";
import { Request } from "../Request";
import { ITag } from "office-ui-fabric-react";
import { BimContactResponse } from "../../models/Bimtrack/BimContactResponse";
import { IHub } from "../../models/Hub";
import { ConfigurationService } from "../ConfigurationService";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Logger } from "../Logger";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { BaseApiService, Method } from "./BaseApiService";
import { ActionListType, ProjectItemFeature, ValueListType } from "./types";
import { BimProjectAttribute } from "../../models/Bimtrack/BimProjectAttribute";

export class ValueListApiService extends BaseApiService {
    constructor(
        private logger: Logger,
        protected newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private configService: ConfigurationService
    ) {
        super(newformaApiClient);
    }

    getPimTrackValueList(
        projectItemFeature: ProjectItemFeature,
        selectedHub: IHub,
        selectedProject: ITag,
        valueListType: ValueListType,
        actionListType?: ActionListType
    ): Promise<Common.GetValueListResponse> {
        this.logger.info(`Get Valuelist for ${valueListType}`);

        const url = this.buildValueListEndpoint(
            this.newformaApiClient.mapDomainRegion(selectedHub),
            selectedHub.key.toString(),
            selectedProject.key.toString(),
            projectItemFeature,
            valueListType,
            actionListType
        );

        return this.newformaApiClient.makeRequest(this.getOptions(url, Method.Get), async (signedOptions: Request) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers)
        );
    }

    getBimTrackDiscipline(selectedHub: IHub, selectedProject: ITag): Promise<BimProjectAttribute[]> {
        this.logger.info("Get Valuelist for discipline");

        const url = this.buildBimTrackDisciplineEndpoint(
            this.configService.bimApiURL,
            selectedHub.key.toString(),
            selectedProject.key.toString()
        );

        return this.newformaApiClient.makeRequest(this.getOptions(url, Method.Get), async (signedOptions: Request) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers)
        );
    }

    getBimTrackUser(selectedHub: IHub, selectedProject: ITag): Promise<BimContactResponse[]> {
        this.logger.info("Get BIM Track user");

        const url = this.buildBimTrackUsersEndpoint(
            this.configService.bimApiURL,
            selectedHub.key.toString(),
            selectedProject.key.toString()
        );

        return this.newformaApiClient.makeRequest(this.getOptions(url, Method.Get), async (signedOptions: Request) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers)
        );
    }

    private buildValueListEndpoint(
        domain: string,
        hubId: string,
        projectId: string,
        projectItemFeature: ProjectItemFeature,
        valueListType: ValueListType,
        actionListType?: ActionListType
    ): string {
        return new URL(
            `${this.basePimUrl(domain, hubId, projectId, projectItemFeature)}/${valueListType}${
                actionListType ? `/${actionListType}` : ""
            }`
        ).toString();
    }

    private buildBimTrackUsersEndpoint(domain: string, hubId: string, projectId: string): string {
        return new URL(`${this.baseBimUrl(domain, hubId, projectId)}/users`).toString();
    }

    private buildBimTrackDisciplineEndpoint(domain: string, hubId: string, projectId: string): string {
        return new URL(`${this.baseBimUrl(domain, hubId, projectId)}/${ValueListType.Disciplines}`).toString();
    }
}
