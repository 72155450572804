import { DateTime } from "@bimone/bimtrack-dates";
import { DefaultLocales } from "../services/TranslationService";
import { OfficeWrapper } from "../services/OfficeWrapper";

export class DateHelpers {
    addBusinessDays(originalDate: Date, daysToAdd: number) {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = daysToAdd;

        const newDate = new Date(originalDate);

        while (daysRemaining > 0) {
            newDate.setDate(newDate.getDate() + 1);
            if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
                daysRemaining--;
            }
        }

        return newDate;
    }

    getDateWithOffset(date: Date, offsetInDays: number): Date {
        const newDate = new Date(date);

        newDate.setDate(newDate.getDate() + offsetInDays);

        return newDate;
    }

    static getStartOfDay(start: Date): string {
        const newDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
        newDate.setUTCHours(0, 0, 0, 0);
        return newDate.toISOString();
    }

    getNewDateWithOffset(offsetInSeconds: number): Date {
        const newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + offsetInSeconds);
        return newDate;
    }

    static formatDate(date: string) {
        const basicFormat = "YYYY-MM-DD";
        return DateTime.fromFormat(date, basicFormat).format(basicFormat);
    }

    static formatTime(date: string) {
        const basicFormat = "HH:mm";
        return DateTime.fromFormat(date, basicFormat).format(basicFormat);
    }

    static getLocalizedDate(date: string) {
        const options: Intl.DateTimeFormatOptions = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };
        return new Date(date).toLocaleString(DefaultLocales[OfficeWrapper.getLanguageCode()], options);
    }
}
