import { Email } from "@newforma/platform-client-api-sdk";
import { TranslateFunction } from "react-localize-redux";
import EmailUser = Office.EmailUser;
import { DateHelpers } from "../helpers/DateHelpers";
import { GraphAPIAttachment, NewMessageFormParams } from "./NewMessageFormParams";

export enum ComposeType {
    Reply = "reply",
    ReplyAll = "replyAll",
    Forward = "forward",
}

export interface ComposeEmailAttachment {
    name: string;
    url: string;
}

export interface ComposeEmailParams {
    composeType: ComposeType;
    email: Pick<Email.EmailDetails, "body" | "from" | "to" | "cc" | "subject" | "sentDate">;
    currentUserEmailAddress: string;
    translate: TranslateFunction;
    attachments: GraphAPIAttachment[];
    ccProjectEmail: string | null;
}

export class ComposeEmail {
    static createNewMessageFormParams(params: ComposeEmailParams): NewMessageFormParams {
        const toRecipients = this.createToRecipients(params);
        const ccRecipients = this.createCcRecipients(params);
        const subject = this.createSubject(params);
        const htmlBody = this.createHtmlBody(params);
        const attachments = params.attachments;

        return {
            toRecipients,
            ccRecipients,
            subject,
            htmlBody,
            attachments,
        };
    }

    private static createToRecipients(params: ComposeEmailParams): EmailUser[] {
        const { email, composeType, currentUserEmailAddress: senderEmail } = params;
        const toRecipients: EmailUser[] = [];

        if ([ComposeType.Reply, ComposeType.ReplyAll].includes(composeType)) {
            toRecipients.push({ emailAddress: email.from?.email || "", displayName: email.from?.name || "" });
        }

        if (composeType === ComposeType.ReplyAll) {
            email.to?.forEach((to) => {
                if (senderEmail === to.email) {
                    return;
                }
                toRecipients.push({ emailAddress: to.email || "", displayName: to.name || "" });
            });
        }

        return this.dedupeEmail(toRecipients);
    }

    private static createCcRecipients(params: ComposeEmailParams): EmailUser[] {
        const { email, composeType } = params;

        const ccRecipients: EmailUser[] = [];

        if (params.ccProjectEmail) {
            ccRecipients?.push({ emailAddress: params.ccProjectEmail, displayName: "" });
        }

        if (composeType !== ComposeType.ReplyAll || !email.cc) {
            return ccRecipients;
        }

        email.cc?.forEach((cc) => {
            ccRecipients.push({ emailAddress: cc.email || "", displayName: cc.name || "" });
        });

        return this.dedupeEmail(ccRecipients);
    }

    private static createSubject(params: ComposeEmailParams): string {
        const prefix = params.translate(`PROJECT_EMAIL.PREFIX_${params.composeType.toUpperCase()}`);
        return `${prefix}: ${params.email.subject || params.translate("PROJECT_EMAIL.NO_SUBJECT")}`;
    }

    private static createHtmlBody(params: ComposeEmailParams): string {
        const from = `${params.email.from?.name} &lt;${params.email.from?.email}&gt;`;
        const to = params.email.to
            ?.map((emailContact) => `${emailContact.name} &lt;${emailContact.email}&gt;`)
            .join(";");
        const cc = params.email.cc
            ?.map((emailContact) => `${emailContact.name} &lt;${emailContact.email}&gt;`)
            .join(";");

        const date = params.email.sentDate ? DateHelpers.getLocalizedDate(params.email.sentDate) : null;

        const append = `<br><br><div id="appendonsend"></div>
        <hr tabindex="-1" style="display:inline-block; width:98%">
        <span style="font-size:11pt font-family: Calibri, sans-serif; color: #000000; ">
            <b>${params.translate("PROJECT_EMAIL.FROM") as string}: </b>${from}<br>
            <b>${params.translate("PROJECT_EMAIL.SENT") as string}: </b>${date}<br>
            <b>${params.translate("PROJECT_EMAIL.TO") as string}: </b>${to}<br>
            ${cc ? `<b>${params.translate("PROJECT_EMAIL.CC") as string}: </b>${cc} <br>` : ""}
            <b>${params.translate("PROJECT_EMAIL.SUBJECT") as string}: </b>${params.email.subject}</span><br> <br>`;

        return params.email.body ? `${append}${params.email.body[0]}` : append;
    }

    private static dedupeEmail(contacts: EmailUser[]): EmailUser[] {
        const addedEmails: string[] = [];

        return contacts.reduce<EmailUser[]>((acc, contact) => {
            const emailAddress = contact.emailAddress.toLowerCase();
            if (addedEmails.indexOf(emailAddress) > -1) {
                return acc;
            }

            addedEmails.push(emailAddress);
            acc.push(contact);

            return acc;
        }, []);
    }
}
