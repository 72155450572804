export interface MsGraphGetEmailAttachmentResponse {
    name: string;
    contentBytes: string;
    contentType: string;
}

/**
 * There are 3 different types of item attachment
 * https://learn.microsoft.com/en-us/graph/api/attachment-get?view=graph-rest-1.0&tabs=http#get-the-raw-contents-of-a-file-or-item-attachment
 */
export enum MsGraphEmailAttachmentOdataType {
    Contact = "#microsoft.graph.contact",
    Event = "#microsoft.graph.event",
    Message = "#microsoft.graph.message",
}

export interface MsGraphItemTypeAttachmentExpandedDetailsResponse {
    "@odata.type": "#microsoft.graph.itemAttachment";
    name: string;
    item: {
        "@odata.type": MsGraphEmailAttachmentOdataType;
    };
}
