import { Project, ProjectsResponse } from "../../models/ProjectsResponse";
import { Request } from "../Request";
import {
    Keyword as ProjectKeyword,
    KeywordListType,
    ProjectKeywordsResponse,
} from "../../models/ProjectKeywordsResponse";
import {
    TeamGroup,
    TeamMember,
    TeamMemberNormalized,
    TeamMemberType,
    TeamMemberView,
    TeamViewResponse,
} from "../../models/projectTeam/TeamViewResponse";
import { Logger } from "../Logger";
import { NewformaApiClient } from "./NewformaApiClient";
import { UserDisplayService } from "../UserDisplayService";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { GetProjectDetailsResponse } from "../../models/GetProjectDetailsResponse";
import { ProjectType } from "../../models/projects/ProjectType";
import { CreateProjectTeamMembersResponse } from "../../models/projectTeam/CreateProjectTeamMembersResponse";
import {
    CreateProjectTeamMemberOperation,
    CreateProjectTeamMemberOps,
    CreateProjectTeamMembersRequest,
} from "../../models/projectTeam/CreateProjectTeamMembersRequest";

export class ProjectsApiService {
    private readonly projectsUri = "/v1/projects";

    constructor(
        private logger: Logger,
        private newformaApiClient: NewformaApiClient,
        private userDisplayService: UserDisplayService,
        private requestWrapper: HttpRequestWrapper
    ) {}

    async getProjects(filter?: string): Promise<ProjectsResponse> {
        this.logger.info(`Retrieving project list with filter '${filter || ""}'`);
        const urlPart = filter ? `?filter=${filter}` : "";

        return this.newformaApiClient.makeRequest(this.getOptions(urlPart), async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getInternalProjects(filter?: string): Promise<Project[]> {
        const projectsResponse = await this.getProjects(filter);
        return projectsResponse.projects.filter((project) => project.type !== ProjectType.shared);
    }

    async getProjectsSupportingActionItems(): Promise<ProjectsResponse> {
        return this.getProjects("actionitems");
    }

    async getProjectsSupportingSubmittals(): Promise<ProjectsResponse> {
        return this.getProjects("submittals");
    }

    async getProjectsSupportingRfis(): Promise<ProjectsResponse> {
        return this.getProjects("rfis");
    }

    async getProjectKeywords(
        projectNrn: string,
        keywordListType: KeywordListType,
        query?: string
    ): Promise<ProjectKeywordsResponse> {
        this.logger.info("Retrieving project keywords");
        const result = await this.getProjectKeywordsPage(projectNrn, keywordListType, undefined, query);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getProjectKeywordsPage(projectNrn, keywordListType, offsetToken, query);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        result.paging = undefined;
        return result;
    }

    async getProjectSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        this.logger.info("Retrieving submittal purposes keywords");
        const result = await this.getProjectSubmittalPurposesKeywordsPage(projectNrn);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getProjectSubmittalPurposesKeywordsPage(projectNrn, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        result.paging = undefined;
        return result;
    }

    async getTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        const teamViewResponse = await this.getTeamMembers(projectNrn, query);

        return this.normalizeTeamMembers(teamViewResponse.items);
    }

    async getTeamMembers(projectNrn: string, query: string = "", offsetToken: string = ""): Promise<TeamViewResponse> {
        this.logger.info("Retrieving project team members");
        const maxPageSize = 50;
        const filterParam = query ? `?q=${query}` : "";
        const offsetTokenParam = offsetToken
            ? `${query ? "&" : "?"}offsetToken=${offsetToken}&maxSize=${maxPageSize}`
            : `${query ? "&" : "?"}maxSize=${maxPageSize}`;
        const urlPath = `/${projectNrn}/team/view${filterParam}${offsetTokenParam}`;

        return this.newformaApiClient.makeRequest(this.getOptions(urlPath), async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getAllTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        const result = await this.getTeamMembers(projectNrn, query);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getTeamMembers(projectNrn, query, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        return this.normalizeTeamMembers(result.items);
    }

    async createProjectTeamMembers(emails: string[], projectNrn: string): Promise<CreateProjectTeamMembersResponse> {
        this.logger.info("Adding new project team members");
        const operations: CreateProjectTeamMemberOperation[] = emails.map((email) => ({
            op: CreateProjectTeamMemberOps.add,
            params: { email },
        }));
        const requestBody: CreateProjectTeamMembersRequest = {
            operations,
        };
        const payload = JSON.stringify(requestBody);

        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.projectsUri}/${encodeURIComponent(
            projectNrn
        )}/team/members`;

        const options: Request = {
            hostname: this.newformaApiClient.getHostName(),
            url: url,
            method: "POST",
            body: payload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.post(url, undefined, signedOptions.headers, payload)
        );
    }

    getProjectDetails(projectNrn: string): Promise<GetProjectDetailsResponse> {
        this.logger.info("Retrieving project details");
        const urlPart = `/${projectNrn}`;

        return this.newformaApiClient.makeRequest(this.getOptions(urlPart), async (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getProjectEmailFilingAddress(projectNrn: string): Promise<string | null> {
        this.logger.info("Retrieving project email filing address");

        const projectDetails = await this.getProjectDetails(projectNrn);
        const filingAddress = projectDetails.fullEmailFilingAddress;

        this.logger.info(`retrieved project email filing address: ${filingAddress}`);
        return filingAddress;
    }

    sortKeywords(keywordsResponse: ProjectKeywordsResponse): ProjectKeyword[] {
        return keywordsResponse.items.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    private getProjectKeywordsPage(
        projectNrn: string,
        keywordListType: KeywordListType,
        offsetToken?: string,
        query?: string
    ): Promise<ProjectKeywordsResponse> {
        const urlPart = `/${projectNrn}/keywords?listType=${keywordListType}&offsetToken=${offsetToken || ""}&query=${
            query || ""
        }`;

        return this.newformaApiClient.makeRequest(this.getOptions(urlPart), async (signedOptions: Request) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    private getProjectSubmittalPurposesKeywordsPage(
        projectNrn: string,
        offsetToken?: string
    ): Promise<ProjectKeywordsResponse> {
        const urlPart = `/${projectNrn}/submittals/workflowactions/log/keywords?offsetToken=${offsetToken || ""}`;

        return this.newformaApiClient.makeRequest(this.getOptions(urlPart), async (signedOptions: Request) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    private getOptions(urlPart: string, method: string = "GET"): Request {
        return {
            hostname: this.newformaApiClient.getHostName(),
            url: `${this.newformaApiClient.getHostNameWithProtocol()}${this.projectsUri}${urlPart}`,
            method,
            headers: { "x-newforma-agent": this.newformaApiClient.getNewformaAgent() },
        };
    }

    private normalizeTeamMembers(teamMembers: TeamMemberView[]): TeamMemberNormalized[] {
        const normalizedTeamMembers: TeamMemberNormalized[] = teamMembers.map((teamMember) => {
            if (teamMember.type === TeamMemberType.GROUP) {
                return { displayName: (teamMember.details as TeamGroup).name, nrn: teamMember.nrn };
            }
            const teamMemberDetails = teamMember.details as TeamMember;
            const discipline = teamMemberDetails.discipline ? teamMemberDetails.discipline.name : "";
            return {
                displayName: this.userDisplayService.getUserDisplayName(
                    teamMemberDetails.email,
                    teamMemberDetails.firstName,
                    teamMemberDetails.lastName
                ),
                nrn: teamMember.nrn,
                email: teamMemberDetails.email,
                discipline,
            };
        });

        return normalizedTeamMembers;
    }
}
