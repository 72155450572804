import { Authenticator } from "../Authenticator";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { ConfigurationService } from "../ConfigurationService";
import { Request } from "../Request";
import { Logger } from "../Logger";
import { BimCredentials } from "../../models/BimCredentials";
import { PimTrackRegion, PimTrackApi } from "../../models/PimModels";
import { NotSupportedValueError } from "../../components/common/errors/NotSupportedValueError";
import { IHub } from "../../models/Hub";

export class NewformaApiClient {
    private readonly refreshErrors: string[] = [
        "bad_request",
        "expired_token",
        "missing_authentication_token",
        "invalid_signature",
    ];

    constructor(private config: ConfigurationService, private authenticator: Authenticator, private logger: Logger) {}

    async makeRequest(options: Request, requestFunc: (headers: Request) => Promise<any>): Promise<any> {
        const accessToken = this.authenticator.getStoredAccessToken();

        if (!accessToken) {
            this.logger.warning("No accessToken found");
            throw new ExpiredSessionError();
        }

        try {
            this.logger.info(`Making Newforma Api Request:\n${JSON.stringify(options)}`);
            return await requestFunc({
                ...options,
                headers: {
                    ...options.headers,
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            this.logger.error(`Error making Newforma API Request\n${JSON.stringify(error)}`);
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.logger.error(`ExpiredSessionError: ${error}`);
                throw error;
            }

            let refreshedCredentials: BimCredentials;
            try {
                refreshedCredentials = await this.authenticator.refreshCredentials();
            } catch (refreshCredentialsError) {
                this.authenticator.logoutFromApp();
                this.logger.error(`Failed to refresh BIM Credentials\n${JSON.stringify(refreshCredentialsError)}`);
                throw new ExpiredSessionError();
            }

            try {
                return await requestFunc({
                    ...options,
                    headers: {
                        ...options.headers,
                        Authorization: `Bearer ${refreshedCredentials.access_token}`,
                    },
                });
            } catch (retryError) {
                this.logger.error(
                    `There was an error when retrying the Newforma API request\n${JSON.stringify(retryError)}`
                );

                if (ExpiredSessionError.isInstanceOf(retryError)) {
                    this.authenticator.logoutFromApp();
                    this.logger.error("ExpiredSessionError:");
                    throw new ExpiredSessionError();
                }

                throw retryError;
            }
        }
    }

    // NOTE: broken
    getHostName(): string {
        const slashIndex = this.config.hostNameWithProtocol[0].url.indexOf("/");
        return this.config.hostNameWithProtocol[0].url.substr(slashIndex + 2);
    }

    // NOTE: broken
    getHostNameWithProtocol(): string {
        return this.config.hostNameWithProtocol[0].url;
    }

    getNewformaAgent(): string {
        return `Outlook Web Add-in/${this.config.version}`;
    }

    // This is a workaround until backend response returns domain with hub
    mapDomainRegion(hub: IHub): string {
        // Production...
        if (this.config.bimAuthURL.includes("bimtrackapp.co")) {
            switch (hub.regionSummary?.Location) {
                case "USEast":
                    return this.getPimTrackDomainByRegion("us-east-1");

                case "CanadaEast":
                case "CanadaEastLocal":
                    return this.getPimTrackDomainByRegion("ca-central-1");

                case "NorthEurope":
                case "Europe":
                    return this.getPimTrackDomainByRegion("eu-central-1");

                default:
                    this.logger.error("NotSupportedValueError:");
                    throw new NotSupportedValueError();
            }

            // Dev/Stage
        } else {
            switch (hub.regionSummary?.Location) {
                case "USEast":
                case "CanadaEast":
                case "CanadaEastLocal":
                    return this.getPimTrackDomainByRegion("us-east-1");

                case "NorthEurope":
                case "Europe":
                    return this.getPimTrackDomainByRegion("eu-central-1");

                default:
                    this.logger.error("NotSupportedValueError:");
                    throw new NotSupportedValueError();
            }
        }
    }

    getPimTrackDomainByRegion(region: PimTrackRegion): string {
        let domain = "";

        this.config.hostNameWithProtocol.forEach((pimTrackApi: PimTrackApi) => {
            if (pimTrackApi.region === region) {
                domain = pimTrackApi.url;
            }
        });

        return domain;
    }
}
