import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./ProjectEmailsViewerComponent.less";
import { Email } from "@newforma/platform-client-api-sdk";
import EmailsViewerMetadataComponent from "./ProjectEmailsViewerMetadata/ProjectEmailsViewerMetadataComponent";
import ProjectEmailsViewerAttachmentsComponent from "./ProjectEmailsViewerAttachments/ProjectEmailsViewerAttachmentsComponent";
import { Icon, TooltipHost } from "office-ui-fabric-react";
import { useEffect } from "react";
import {
    ProjectEmailViewerMessageActions,
    ProjectEmailViewerMessage,
    ExtendedEmailDetails,
} from "../../../models/ProjectEmailViewer";

export interface ProjectEmailsViewerComponentProps extends LocalizeContextProps {
    states: {
        emails: ExtendedEmailDetails[];
        selectedEmail: ExtendedEmailDetails;
    };
    actions: {
        getEmailAttachment: (emailId: string, attachment: Email.EmailAttachmentDetails) => Promise<void>;
        setEmails: (emails: ExtendedEmailDetails[]) => void;
        sendOutgoingMessage: (message: ProjectEmailViewerMessage) => void;
        setSelectedEmail: (email: ExtendedEmailDetails | null) => void;
    };
}

function ProjectEmailsViewerComponent(props: ProjectEmailsViewerComponentProps): JSX.Element {
    const {
        states: { emails, selectedEmail },
        actions: { setEmails, getEmailAttachment, sendOutgoingMessage, setSelectedEmail },
    } = props;
    const [selectedEmailTab, setSelectedEmailTab] = React.useState<ExtendedEmailDetails | null>(null);

    useEffect(() => {
        if (selectedEmail) {
            const updatedSelectedEmail = emails.find((email) => email.messageId === selectedEmail.messageId);
            setSelectedEmailTab(updatedSelectedEmail ?? null);
        }
    }, [selectedEmail]);

    const handleToggleParticipants = (messageId: string) => {
        const updatedEmails = emails.map((email) =>
            email.messageId === messageId ? { ...email, isParticipantsExpanded: !email.isParticipantsExpanded } : email
        );

        if (selectedEmailTab?.messageId === messageId) {
            const updatedSelectedEmail = updatedEmails.find((email) => email.messageId === messageId);
            setSelectedEmailTab(updatedSelectedEmail ?? null);
        }
        messageParent(updatedEmails);
        setEmails(updatedEmails);
    };

    const handleToggleAttachments = (messageId: string) => {
        const updatedEmails = emails.map((email) =>
            email.messageId === messageId ? { ...email, isAttachmentsExpanded: !email.isAttachmentsExpanded } : email
        );

        if (selectedEmailTab?.messageId === messageId) {
            const updatedSelectedEmail = updatedEmails.find((email) => email.messageId === messageId);
            setSelectedEmailTab(updatedSelectedEmail ?? null);
        }
        messageParent(updatedEmails);
        setEmails(updatedEmails);
    };

    const onTabClick = (emailTab: ExtendedEmailDetails) => {
        setSelectedEmailTab(emailTab);
    };

    const messageParent = (updatedEmailList: ExtendedEmailDetails[]) => {
        const messageToSend: ProjectEmailViewerMessage = {
            action: ProjectEmailViewerMessageActions.PROJECT_EMAIL_VIEWER_UPDATED,
            data: {
                emails: updatedEmailList,
                selectedEmail,
            },
        };

        sendOutgoingMessage(messageToSend);
    };

    const onRemoveEmail = (messageId: string) => {
        const updatedEmails = emails.filter((email) => email.messageId !== messageId);
        setEmails(updatedEmails);

        messageParent(updatedEmails);

        // if selected email is removed, the first email in the list becomes selected
        if (selectedEmailTab?.messageId === messageId && updatedEmails.length > 0) {
            setSelectedEmailTab(updatedEmails[0]);
            setSelectedEmail(updatedEmails[0]);
        }
    };

    return (
        <div className="newforma-emailsViewerComponent">
            {selectedEmailTab && (
                <div className="newforma-tabsContainer">
                    {emails.map((email, idx) => (
                        <TooltipHost hostClassName="newforma-tooltipHost" content={email.subject} key={email.messageId}>
                            <div
                                className={`newforma-tabItem ${
                                    selectedEmailTab.messageId === email.messageId ? "selected" : ""
                                }`}
                                role="tab"
                                tabIndex={idx}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" || e.key === " ") {
                                        onTabClick(email);
                                    }
                                }}
                                onClick={() => onTabClick(email)}
                            >
                                <Icon iconName="MailSolid" className="newforma-mailIcon" />
                                <span className="newforma-tabText" data-testid="newforma-tabSpan">
                                    {email.subject}
                                </span>
                                <Icon
                                    iconName="Cancel"
                                    className="newforma-removeIcon"
                                    data-testid="newforma-removeIcon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onRemoveEmail(email.messageId);
                                    }}
                                />
                            </div>
                        </TooltipHost>
                    ))}
                </div>
            )}

            {selectedEmailTab && (
                <div className="newforma-emailsViewerComponentContainer">
                    <EmailsViewerMetadataComponent
                        email={selectedEmailTab}
                        toggleParticipantsExpanded={handleToggleParticipants}
                        isExpanded={selectedEmailTab?.isParticipantsExpanded ?? false}
                    />
                    <ProjectEmailsViewerAttachmentsComponent
                        email={selectedEmailTab}
                        getEmailAttachment={getEmailAttachment}
                        toggleParticipantsExpanded={handleToggleAttachments}
                        isExpanded={selectedEmailTab?.isAttachmentsExpanded ?? false}
                    />
                    <div
                        className="newforma-emailsViewerComponentBody"
                        dangerouslySetInnerHTML={{ __html: selectedEmailTab?.body ?? "" }}
                    />
                </div>
            )}
        </div>
    );
}

export default withLocalize(ProjectEmailsViewerComponent);
