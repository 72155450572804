import * as React from "react";
import { createContext, useContext, useState, useMemo } from "react";
import { ITag } from "office-ui-fabric-react";
import { IHub } from "../models/Hub";
import { ExtendedEmailDetails } from "../models/ProjectEmailViewer";

export interface EmailsViewerAppProviderState {
    appState: {
        emails: ExtendedEmailDetails[];
        selectedEmail: ExtendedEmailDetails | null;
        selectedProject: ITag | null;
        selectedHub: IHub | null;
    };
    actions: {
        setEmails: React.Dispatch<React.SetStateAction<ExtendedEmailDetails[]>>;
        setSelectedEmail: React.Dispatch<React.SetStateAction<ExtendedEmailDetails | null>>;
        setSelectedProject: React.Dispatch<React.SetStateAction<ITag | null>>;
        setSelectedHub: React.Dispatch<React.SetStateAction<IHub | null>>;
    };
}

const DEFAULT_VALUE: EmailsViewerAppProviderState = {
    appState: {
        emails: [],
        selectedEmail: null,
        selectedProject: null,
        selectedHub: null,
    },

    actions: {
        setEmails: () => {},
        setSelectedEmail: () => {},
        setSelectedProject: () => {},
        setSelectedHub: () => {},
    },
};

const AppContext = createContext<EmailsViewerAppProviderState>(DEFAULT_VALUE);

interface AppProviderProps {
    children: React.ReactNode;
}

export const EmailsViewerAppProvider = (props: AppProviderProps) => {
    const [emails, setEmails] = useState<ExtendedEmailDetails[]>([]);
    const [selectedProject, setSelectedProject] = useState<ITag | null>(null);
    const [selectedHub, setSelectedHub] = useState<IHub | null>(null);
    const [selectedEmail, setSelectedEmail] = useState<ExtendedEmailDetails | null>(null);
    const emailViewerAppProviderValue: EmailsViewerAppProviderState = useMemo(
        () => ({
            appState: {
                emails,
                selectedEmail,
                selectedProject,
                selectedHub,
            },
            actions: {
                setEmails,
                setSelectedEmail,
                setSelectedHub,
                setSelectedProject,
            },
        }),
        [emails]
    );

    return <AppContext.Provider value={emailViewerAppProviderValue}>{props.children}</AppContext.Provider>;
};

export function useEmailViewerAppState(): EmailsViewerAppProviderState {
    const contextData = useContext(AppContext);
    return useMemo(() => ({ ...contextData }), [contextData]);
}
