import { IOverflowSetItemProps } from "office-ui-fabric-react";
import { ProjectEmailActionsProps } from "../components/projectEmail/ProjectEmailActions/ProjectEmailActions";
import { ComposeType } from "../models/ComposeEmail";
import { useAppService } from "../services/AppServiceProvider";
import { ProjectEmailViewerMessageActions, ProjectEmailViewerMessage } from "../models/ProjectEmailViewer";

const useProjectEmailViewerActions = (props: ProjectEmailActionsProps) => {
    const {
        services: { configService, officeWrapper },
    } = useAppService();

    const handleActionClick = async (composeType: ComposeType) => {
        const message: ProjectEmailViewerMessage = {
            action: ProjectEmailViewerMessageActions.PROJECT_EMAIL_VIEWER_ACTION_CLICKED,
            data: {
                messageId: props.item.messageId,
                composeType,
            },
        };
        officeWrapper.contextUiMessageParent(JSON.stringify(message), { targetOrigin: configService.originURL });
    };

    const projectEmailViewerActions: IOverflowSetItemProps[] = [
        {
            key: "reply",
            iconProps: { iconName: "Reply" },
            className: "reply-button",
            onClick: () => handleActionClick(ComposeType.Reply),
        },
        {
            key: "replyAll",
            iconProps: { iconName: "ReplyAll" },
            onClick: () => handleActionClick(ComposeType.ReplyAll),
            className: "reply-button",
        },
        {
            key: "forward",
            iconProps: { iconName: "ReplyMirrored" },
            onClick: () => handleActionClick(ComposeType.Forward),
            className: "reply-button",
        },
    ];

    return {
        projectEmailViewerActions,
        handleActionClick,
    };
};

export default useProjectEmailViewerActions;
