import { Logger } from "../Logger";
import { ConfigurationService } from "../ConfigurationService";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { IssueResponse } from "../../models/IssueResponse";
import { Request } from "../Request";
import { IHub } from "../../models/Hub";
import { ITag } from "office-ui-fabric-react";
import { IssueRequest } from "../../models/IssueRequest";
import { ProjectItemFeature } from "../PimTrackApi/types";
import { BaseApiService, Method } from "../PimTrackApi/BaseApiService";
import { FileWithId } from "../../models/shared/FileWithId";
import { OutlookApiService } from "../OutlookApiService";

export class IssueApiService extends BaseApiService {
    constructor(
        private logger: Logger,
        private configService: ConfigurationService,
        protected newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private outlookApiService: OutlookApiService
    ) {
        super(newformaApiClient);
    }

    async logIssue(selectedHub: IHub, selectedProject: ITag, payload: IssueRequest): Promise<IssueResponse> {
        this.logger.info("Logging an issue");
        const url = `${this.baseBimUrl(
            this.configService.bimApiURL,
            selectedHub.key.toString(),
            selectedProject.key.toString()
        )}/issues`;

        return this.newformaApiClient.makeRequest(this.getOptions(url, Method.Post), (signedOptions) =>
            this.requestWrapper.post(url, undefined, signedOptions.headers, JSON.stringify(payload))
        );
    }

    async createIssueAttachments(
        selectedHub: IHub,
        selectedProject: ITag,
        issueId: string,
        attachments: (Office.AttachmentDetails | FileWithId)[]
    ): Promise<string[]> {
        this.logger.info("Creating attachments for issue");

        const attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(attachments);
        if (!attachmentsDetails.length) {
            return Promise.resolve([]);
        }

        const url = `${this.baseBimUrl(
            this.configService.bimApiURL,
            selectedHub.key.toString(),
            selectedProject.key.toString()
        )}/issues/${issueId}/attachments`;

        const formData = new FormData();
        for (let i = 0; i < attachmentsDetails.length; i++) {
            formData.append(`file-${i}`, new File([attachmentsDetails[i].file], attachmentsDetails[i].fileName));
        }

        const options: Request = {
            hostname: this.configService.bimApiURL,
            url,
            method: Method.Post,
            body: formData,
            headers: {
                Accept: "application/json",
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, formData, {
                processData: false,
                contentType: false,
            })
        );
    }

    getIssueUrl(issueNumber: number, selectedHub: IHub, selectedProject: ITag): string {
        const bimUrl = new URL(this.configService.webAppURL);

        return new URL(
            `${bimUrl.protocol}//${selectedHub.name}.${bimUrl.hostname}/projects/${selectedProject.key}/${
                ProjectItemFeature.Issues
            }/${issueNumber.toString()}`
        ).toString();
    }
}
