import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Icon } from "office-ui-fabric-react";
import "./ProjectEmailsViewerMetadataRecipientsComponent.less";
import { Email } from "@newforma/platform-client-api-sdk";
import { getEmailDisplayString } from "../../../../../utils/helpers";

interface EmailsViewerMetadataRecipientsProps extends LocalizeContextProps {
    recipients: Email.EmailContact[];
    toggleParticipantsExpanded: () => void;
    isExpanded: boolean;
}

function ProjectEmailsViewerMetadataRecipientsComponent({
    recipients,
    toggleParticipantsExpanded,
    isExpanded,
}: EmailsViewerMetadataRecipientsProps): JSX.Element {
    return (
        <div className="recipientsList">
            <span className="newforma-toField">
                {getEmailDisplayString(recipients[0])}
                {recipients.length > 1 && (
                    <span className="newforma-andMoreSpan">
                        {" "}
                        {isExpanded ? "" : `+${recipients.length - 1}`}
                        <Icon
                            data-testid="recipientsList-chevron"
                            onClick={toggleParticipantsExpanded}
                            className="newforma-chevronIcon"
                            iconName={!isExpanded ? "ChevronDownSmall" : "ChevronUpSmall"}
                        />
                    </span>
                )}
            </span>
            {isExpanded && (
                <div className="expandedRecipients">
                    {recipients.slice(1).map((recipient) => (
                        <div key={recipient.email}>{getEmailDisplayString(recipient)}</div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default withLocalize(ProjectEmailsViewerMetadataRecipientsComponent);
