import moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';
import DateTime from './DateTime';

export const momentRange = extendMoment(require('moment-timezone'));

export default class DateTimeRange {
  public constructor(public readonly start: DateTime, public readonly end: DateTime) {}

  public overlaps(other: DateTimeRange): boolean {
    return DateTimeRange.createDateRange(this.start, this.end).overlaps(
      DateTimeRange.createDateRange(other.start, other.end)
    );
  }

  public contains(dateTime: DateTime): boolean {
    return DateTimeRange.createDateRange(this.start, this.end).contains(DateTimeRange.createMoment(dateTime));
  }

  public isEqual(other: DateTimeRange): boolean {
    return DateTimeRange.areEqual(this, other);
  }

  public static areEqual(first?: DateTimeRange, second?: DateTimeRange): boolean {
    return first?.start === second?.start && first?.end === second?.end;
  }

  public static daysFrom(dateTime: DateTime, days: number): DateTimeRange {
    const first = DateTimeRange.createMoment(dateTime);
    const second = first.clone().add(days, 'day');

    if (days >= 0) {
      return DateTimeRange.createDateTimeRange(first, second);
    }

    return DateTimeRange.createDateTimeRange(second, first);
  }

  public static yesterday(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime).subtract(1, 'day');

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().startOf('day'), dateTimeAsMoment.endOf('day'));
  }

  public static last24Hours(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().subtract(1, 'day'), dateTimeAsMoment);
  }

  public static currentDay(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().startOf('day'), dateTimeAsMoment.endOf('day'));
  }

  public static lastWeek(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime).subtract(1, 'week');

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().startOf('week'), dateTimeAsMoment.endOf('week'));
  }

  public static pastWeek(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().subtract(1, 'week'), dateTimeAsMoment);
  }

  public static currentWeek(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().startOf('week'), dateTimeAsMoment.endOf('week'));
  }

  public static lastMonth(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime).subtract(1, 'month');

    return DateTimeRange.createDateTimeRange(
      dateTimeAsMoment.clone().startOf('month'),
      dateTimeAsMoment.endOf('month')
    );
  }

  public static pastMonth(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().subtract(1, 'month'), dateTimeAsMoment);
  }

  public static currentMonth(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(
      dateTimeAsMoment.clone().startOf('month'),
      dateTimeAsMoment.endOf('month')
    );
  }

  public static pastYear(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().subtract(1, 'year'), dateTimeAsMoment);
  }

  public static lastYear(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime).subtract(1, 'year');

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().startOf('year'), dateTimeAsMoment.endOf('year'));
  }

  public static currentYear(dateTime: DateTime): DateTimeRange {
    const dateTimeAsMoment = DateTimeRange.createMoment(dateTime);

    return DateTimeRange.createDateTimeRange(dateTimeAsMoment.clone().startOf('year'), dateTimeAsMoment.endOf('year'));
  }

  private static createDateTimeRange(start: moment.Moment, end: moment.Moment): DateTimeRange {
    return new DateTimeRange(DateTimeRange.createDateTime(start), DateTimeRange.createDateTime(end));
  }

  private static createDateTime(value: moment.Moment): DateTime {
    return DateTime.fromISOString(value.toISOString());
  }

  private static createDateRange(start: DateTime, end: DateTime): DateRange {
    return new DateRange(DateTimeRange.createMoment(start), DateTimeRange.createMoment(end));
  }

  private static createMoment(dateTime: DateTime): moment.Moment {
    return momentRange(dateTime.toISOString());
  }
}
