import { MessageBarType, registerIcons } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import { RedirectComponent } from "./components/common/RedirectComponent";
import FileMultipleEmailComponent from "./components/fileMultipleEmail/FileMultipleEmailComponent";
import AppHeaderComponent from "./components/header/AppHeaderComponent";
import HubSelectorDashboard from "./components/hubSelectorDashboard/HubSelectorDashboard";
import IssueComponentWrapper from "./components/issue/IssueComponentWrapper";
import LoginComponent from "./components/login/LoginComponent";
import RfiComponentWrapper from "./components/rfi/RfiComponentWrapper";
import SendAndFileComponent from "./components/sendAndFile/SendAndFileComponent";
import SupportedFunctionalities from "./components/shared/SupportedFunctionalities/SupportedFunctionalities";
import CheckCircleIcon from "./components/shared/icons/CheckCircleIcon";
import CloseCircleIcon from "./components/shared/icons/CloseCircleIcon";
import FileBackIcon from "./components/shared/icons/FileBackIcon";
import OpenExternal from "./components/shared/icons/OpenExternal";
import { WorkflowItemProvider } from "./components/shared/itemSuccess/ItemContext";
import SubmittalComponentWrapper from "./components/submittal/SubmittalComponentWrapper";
import { DateHelpers } from "./helpers/DateHelpers";
import { FormValidationHelpers } from "./helpers/FormValidationHelpers";
import { SendAndFileHelpers } from "./helpers/SendAndFile/SendAndFileHelpers";
import { useProgressBar } from "./components/shared/progressBar/useProgressBar";
import { useToast } from "./components/shared/toast/useToast";
import { ExpiredSessionError, InvalidGraphSessionError } from "./models/ExpiredSessionError";
import { Hub, IHub } from "./models/Hub";
import { UserInfo } from "./models/PimModels";
import { LocalStorageKeys, SessionStorageKeys } from "./models/StorageKeys";
import { AnalyticsManager } from "./services/AnalyticsManager";
import { Authenticator } from "./services/Authenticator";
import { BimProjectsApiService } from "./services/BimApi/BimProjectsApiService";
import { IssueApiService } from "./services/BimApi/IssueApiService";
import { ConfigurationService } from "./services/ConfigurationService";
import { CustomerSupportEmailService } from "./services/CustomerSupportEmailService";
import { Logger } from "./services/Logger";
import { EmailApiService } from "./services/NewformaApi/EmailApiService";
import { NewformaApiClient } from "./services/NewformaApi/NewformaApiClient";
import { ProjectsApiService } from "./services/NewformaApi/ProjectsApiService";
import { OfficeRoamingSettings } from "./services/OfficeRoamingSettings";
import { OfficeWrapper } from "./services/OfficeWrapper";
import { FileUploadApiService } from "./services/PimTrackApi/FileUploadApiService";
import { RelatedItemApiService } from "./services/PimTrackApi/RelatedItemApiService";
import { RfiApiService } from "./services/PimTrackApi/RfiApiService";
import { SubmittalApiService } from "./services/PimTrackApi/SubmittalApiService";
import { ValueListApiService } from "./services/PimTrackApi/ValueListApiService";
import { SmartFilingManager } from "./services/SmartFiling/SmartFilingManager";
import { StorageWrapper } from "./services/StorageWrapper";
import { SubscriptionApiService } from "./services/SubscriptionApiService/SubscriptionApiService";
import { TranslationService } from "./services/TranslationService";
import { UnauthenticatedApiClient } from "./services/UnauthenticatedApiClient";
import { WindowWrapper } from "./services/WindowWrapper";
import { useAppState } from "./store/AppProvider";
import { ROUTE_PATH_NAMES } from "./utils/constants";
import ProjectEmailComponent from "./components/projectEmail/ProjectEmailComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import FeatureFlagProvider from "./components/featureFlag/FeatureFlagProvider";
import ProjectEmailSearchProvider from "./hooks/useProjectEmailSearch";
import { EmailsViewerAppProvider } from "./store/EmailsViewerAppProvider";

export interface AppComponentProps extends LocalizeContextProps {
    officeRoamingSettings: OfficeRoamingSettings;
    authenticator: Authenticator;
    apiClient: NewformaApiClient;
    unauthenticatedApiClient: UnauthenticatedApiClient;
    analyticsManager: AnalyticsManager;
    smartFilingManager: SmartFilingManager;
    officeWrapper: OfficeWrapper;
    configService: ConfigurationService;
    storageWrapper: StorageWrapper;
    windowWrapper: WindowWrapper;
    formValidationHelpers: FormValidationHelpers;
    translationService: TranslationService;
    customerSupportEmailService: CustomerSupportEmailService;
    logger: Logger;
    projectsApiService: ProjectsApiService;
    bimProjectsApiService: BimProjectsApiService;
    sendAndFileHelpers: SendAndFileHelpers;
    dateHelpers: DateHelpers;
    rfiApiService: RfiApiService;
    emailApiService: EmailApiService;
    valueListApiServices: ValueListApiService;
    fileUploadApiService: FileUploadApiService;
    relatedItemApiService: RelatedItemApiService;
    subscriptionApiService: SubscriptionApiService;
    submittalApiService: SubmittalApiService;
    issueApiService: IssueApiService;
}

Office.initialize = () => {
    // NOTE: Keep - required. This also fires before .async()
};
// tslint:disable-next-line
(async () => {
    await Office.onReady();
})();

registerIcons({
    icons: {
        FileBack: <FileBackIcon />,
        OpenExternal: <OpenExternal />,
        CloseCircle: <CloseCircleIcon />,
        CheckCircle: <CheckCircleIcon />,
    },
});

function AppComponent(props: AppComponentProps) {
    const {
        appState: {
            deleteEmailAfterFilingSetting,
            entryPointUrl,
            locationPath,
            isLoggedIn,
            selectedHub,
            navigationPage,
            hubs,
            selectedProject,
            addinUserInfo,
            supportedAddinItems,
        },
        actions: {
            setMailboxItem,
            setEntryPointUrl,
            handleLocationPath,
            setIsLoggedIn,
            setNavigationPage,
            setHubs,
            setAddinUserInfo,
            handleSelectedProject,
        },
    } = useAppState();

    const { showToast, dismissToast } = useToast();
    const { showProgress } = useProgressBar();

    // Constructor
    React.useEffect(() => {
        props.authenticator.onAuthenticationError = onAuthenticationError;
        props.officeWrapper.addHandlerAsync(Office.EventType.ItemChanged, itemChanged);
        props.translationService.getTranslations(props);
    }, []);

    // ComponentDidMount
    React.useEffect(() => {
        (async () => {
            if (locationPath !== ROUTE_PATH_NAMES.LOGIN_PATH) {
                props.storageWrapper.saveSessionStorage(SessionStorageKeys.urlLocation, locationPath);
            }
            if (locationPath === ROUTE_PATH_NAMES.FILE_MULTIPLE_EMAIL) {
                props.storageWrapper.saveSessionStorage(
                    SessionStorageKeys.urlLocation,
                    ROUTE_PATH_NAMES.FILE_MULTIPLE_EMAIL
                );
                handleLocationPath(ROUTE_PATH_NAMES.FILE_MULTIPLE_EMAIL);
                setEntryPointUrl(ROUTE_PATH_NAMES.FILE_MULTIPLE_EMAIL);
            }
            if (locationPath === ROUTE_PATH_NAMES.SEND_AND_FILE || locationPath === "sendandfile") {
                setEntryPointUrl(ROUTE_PATH_NAMES.SEND_AND_FILE);
            }

            props.logger.info(`Add-in loaded. Entry Point: ${entryPointUrl}`);
            await loadHubs();
            loadUserInfo();
            await storeUserHubPreference();
            await disableAptWidget();
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            await disableAptWidget();
        })();
    }, [isLoggedIn]);

    const loadHubs = async (): Promise<void> => {
        try {
            const hubsResponse: Hub[] = await props.bimProjectsApiService.getHubsList();
            const sortedhubsResponse = hubsResponse.sort((a, b) => a.Name.localeCompare(b.Name));
            const hubsITag: IHub[] = sortedhubsResponse.map(
                (hub) => ({ key: hub.Id, name: hub.Name, regionSummary: hub.RegionSummary, image: hub.Image } as IHub)
            );
            setHubs(hubsITag);
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                await onUserLogOut();
                props.logger.info(`ExpiredSessionError: ${JSON.stringify(error)}`);
                setHubs([]);
                return;
            } else if (InvalidGraphSessionError.isInstanceOf(error)) {
                await onUserLogOut();
                props.logger.info(`InvalidGraphSessionError: ${JSON.stringify(error)}`);
                setHubs([]);
                return;
            }
        }
    };

    // handles
    const onUserLogOut = (): Promise<void> => {
        props.logger.info("User signed out of add-in");

        setIsLoggedIn(false);
        setHubs(undefined);
        handleSelectedProject(null);

        return props.authenticator.logoutFromApp();
    };

    const onApiLogIn = async (matchingUser: boolean, userInfo: UserInfo): Promise<void> => {
        props.logger.info(`Outlook user: ${props.officeWrapper.userProfileEmailAddress}`);

        if (!matchingUser) {
            showToast({ message: props.translate("APP.WRONG_EMAIL_LOG_IN") as string }, MessageBarType.error);
            await onUserLogOut();
            props.logger.warning("Mismatching User");
            return;
        }

        await storeUserInfo(userInfo);
        setIsLoggedIn(true);
        await loadHubs();
        props.logger.info("Login success:");
        props.logger.info(`Stored Addin user: ${props.storageWrapper.loadLocalStorage(LocalStorageKeys.addInUser)}`);
    };

    const onAuthenticationError = async (errorMessage: string): Promise<void> => {
        showToast({ message: props.translate(errorMessage) as string }, MessageBarType.error);
    };

    const itemChanged = async (_eventArgs: any): Promise<void> => {
        props.logger.info("Selected mail item changed");
        const currentMailboxItem = props.officeWrapper.currentContextItem;
        if (_eventArgs.type !== "olkItemSelectedChanged" || !currentMailboxItem) {
            setMailboxItem(null);
            return;
        }

        setMailboxItem(currentMailboxItem);

        dismissToast();
    };

    const isFilingOptionSupportedOnSelectedScreen = React.useMemo(() => {
        const pathname = locationPath as ROUTE_PATH_NAMES;
        const supportedFilingOptions = supportedAddinItems;
        const isEmailSupportedOnLocationPath =
            [
                ROUTE_PATH_NAMES.FILE_MULTIPLE_EMAIL,
                ROUTE_PATH_NAMES.ROOT,
                ROUTE_PATH_NAMES.SEND_AND_FILE,
                // TODO #34595 temporary menu to be removed once we do have a menu available for ProjectEmail
                ROUTE_PATH_NAMES.PROJECT_EMAIL,
                "sendandfile",
            ].includes(pathname) && supportedFilingOptions?.canUseEmail;
        const isRFISupportedOnLocationPath =
            pathname === ROUTE_PATH_NAMES.RFI && supportedFilingOptions?.canUseWorkflow;
        const isSubmittalsSupportedOnLocationPath =
            pathname === ROUTE_PATH_NAMES.SUBMITTAL && supportedFilingOptions?.canUseWorkflow;
        const isIssueSupportedOnLocationPath =
            pathname === ROUTE_PATH_NAMES.ISSUE && supportedFilingOptions?.canUseIssues;
        return (
            !!isEmailSupportedOnLocationPath ||
            !!isRFISupportedOnLocationPath ||
            !!isSubmittalsSupportedOnLocationPath ||
            !!isIssueSupportedOnLocationPath
        );
    }, [supportedAddinItems, locationPath]);

    const disableAptWidget = async (): Promise<void> => {
        let counter = 0;
        while (counter < 10) {
            await new Promise((resolve) => setTimeout(resolve, 1000));

            const element =
                window.document.getElementById("apt-widget") || window.document.getElementById("px-engagement-wrapper");

            if (!element) {
                counter++;
            } else {
                element.style.display = "none";
                props.logger.info("Disabled GainSight Knowledge Center");
                break;
            }
        }
    };

    const handleDiagnostic = async (): Promise<string> => {
        const diagnosticsArr: string[] = [
            "------------------------Diagnostics------------------------",
            `Office User Name:  ${props.officeWrapper.userProfileDisplayName}`,
            `Customer Domain: ${props.officeWrapper.userProfileEmailDomain}`,
            `Time Zone: ${props.officeWrapper.getTimezone()}`,
            `Newforma Konekt Add-in Version ${props.configService.version}`,
            `Mail Client: ${props.officeWrapper.getClientPlatform()}`,
            `Mail Client Version: ${props.officeWrapper.getClientVersion()}`,
            `Office API version ${props.officeWrapper.getLatestSupportedOfficeApiVersion() || "unknown"}\n`,
            "Logs:",
        ].concat(props.logger.getLogs());

        const diagnostics = diagnosticsArr.join("\n");
        props.windowWrapper.copyTextToClipboard(diagnostics);
        showToast({ message: "Logs Copied to Clipboard" }, MessageBarType.success);

        return diagnostics;
    };

    const storeUserInfo = async (addInUser: UserInfo): Promise<void> => {
        props.storageWrapper.saveLocalStorage(LocalStorageKeys.addInUser, JSON.stringify(addInUser));
        setAddinUserInfo(addInUser);
    };

    const loadUserInfo = (): void => {
        const userInfo = props.storageWrapper.loadLocalStorage(LocalStorageKeys.addInUser);

        if (userInfo) {
            const storedAddinUserInfo: UserInfo = JSON.parse(userInfo);
            setAddinUserInfo(storedAddinUserInfo);
        }
    };

    const storeUserHubPreference = async (): Promise<void> => {
        if (addinUserInfo && selectedHub) {
            const addInUserHubPreference = await props.bimProjectsApiService.fetchUserPreferencesByHub(
                addinUserInfo,
                selectedHub
            );
            props.storageWrapper.saveLocalStorage(
                LocalStorageKeys.userHubPreference,
                JSON.stringify(addInUserHubPreference)
            );
        }
    };

    const queryClient = new QueryClient();

    return (
        <div className="newforma-appMain">
            <QueryClientProvider client={queryClient}>
                <HashRouter>
                    <AppHeaderComponent
                        configService={props.configService}
                        officeRoamingSettings={props.officeRoamingSettings}
                        officeWrapper={props.officeWrapper}
                        logger={props.logger}
                        windowWrapper={props.windowWrapper}
                        smartFilingManager={props.smartFilingManager}
                        storageWrapper={props.storageWrapper}
                        bimProjectsApiService={props.bimProjectsApiService}
                        isFilingOptionSupportedOnSelectedScreen={isFilingOptionSupportedOnSelectedScreen}
                        handleDiagnostic={handleDiagnostic}
                        onLogOut={onUserLogOut}
                        onShowToast={showToast}
                    />
                    <Switch>
                        <Route exact path={ROUTE_PATH_NAMES.LOGIN_PATH}>
                            <LoginComponent
                                isLoggedIn={isLoggedIn}
                                authenticator={props.authenticator}
                                onApiLogin={onApiLogIn}
                                redirectUrl={entryPointUrl}
                                apiClient={props.unauthenticatedApiClient}
                                officeWrapper={props.officeWrapper}
                                logger={props.logger}
                                analyticsManager={props.analyticsManager}
                                bimProjectApiService={props.bimProjectsApiService}
                                handleDiagnostic={handleDiagnostic}
                            />
                        </Route>
                        <RedirectComponent isLoggedIn={isLoggedIn}>
                            <FeatureFlagProvider>
                                <HubSelectorDashboard
                                    userEmail={addinUserInfo?.email}
                                    configService={props.configService}
                                    hubs={hubs}
                                    onLogOut={onUserLogOut}
                                    navigationPage={navigationPage}
                                    storageWrapper={props.storageWrapper}
                                    officeWrapper={props.officeWrapper}
                                    logger={props.logger}
                                >
                                    <SupportedFunctionalities
                                        isFilingOptionSupportedOnSelectedScreen={
                                            isFilingOptionSupportedOnSelectedScreen
                                        }
                                        supportedFilingOptions={supportedAddinItems}
                                    >
                                        <>
                                            <Route
                                                exact
                                                path={[ROUTE_PATH_NAMES.ROOT, ROUTE_PATH_NAMES.FILE_MULTIPLE_EMAIL]}
                                            >
                                                <FileMultipleEmailComponent
                                                    actions={{
                                                        onExpiredSession: onUserLogOut,
                                                        onShowToast: showToast,
                                                        onSetNavigationPage: setNavigationPage,
                                                        showProgress: showProgress,
                                                        translate: props.translate,
                                                    }}
                                                    states={{
                                                        deleteEmailAfterFiling: deleteEmailAfterFilingSetting,
                                                    }}
                                                    services={{
                                                        emailApiService: props.emailApiService,
                                                        authenticator: props.authenticator,
                                                        logger: props.logger,
                                                    }}
                                                />
                                            </Route>
                                            <Route exact path={ROUTE_PATH_NAMES.PROJECT_EMAIL}>
                                                <ProjectEmailSearchProvider>
                                                    <EmailsViewerAppProvider>
                                                        <ProjectEmailComponent />
                                                    </EmailsViewerAppProvider>
                                                </ProjectEmailSearchProvider>
                                            </Route>
                                            <Route exact path={ROUTE_PATH_NAMES.SEND_AND_FILE}>
                                                <SendAndFileComponent
                                                    selectedProject={selectedProject}
                                                    configService={props.configService}
                                                    smartFilingManager={props.smartFilingManager}
                                                    onExpiredSession={onUserLogOut}
                                                    officeWrapper={props.officeWrapper}
                                                    logger={props.logger}
                                                    sendAndFileHelpers={props.sendAndFileHelpers}
                                                    onSetNavigationPage={setNavigationPage}
                                                    onShowToast={showToast}
                                                    bimProjectsApiService={props.bimProjectsApiService}
                                                    storageWrapper={props.storageWrapper}
                                                    selectedHub={selectedHub}
                                                    onProjectSelected={handleSelectedProject}
                                                />
                                            </Route>
                                            <Route exact path={ROUTE_PATH_NAMES.RFI}>
                                                <WorkflowItemProvider>
                                                    <RfiComponentWrapper
                                                        formValidationHelpers={props.formValidationHelpers}
                                                        officeWrapper={props.officeWrapper}
                                                        dateHelpers={props.dateHelpers}
                                                        rfiApiService={props.rfiApiService}
                                                        onShowToast={showToast}
                                                        valueListApiServices={props.valueListApiServices}
                                                        selectedProject={selectedProject}
                                                        selectedHub={selectedHub}
                                                        showProgress={showProgress}
                                                        fileUploadApiService={props.fileUploadApiService}
                                                        emailApiService={props.emailApiService}
                                                        relatedItemApiService={props.relatedItemApiService}
                                                        supportedAddinItems={supportedAddinItems}
                                                        bimProjectsApiService={props.bimProjectsApiService}
                                                        officeRoamingSettings={props.officeRoamingSettings}
                                                    />
                                                </WorkflowItemProvider>
                                            </Route>
                                            <Route exact path={ROUTE_PATH_NAMES.SUBMITTAL}>
                                                <WorkflowItemProvider>
                                                    <SubmittalComponentWrapper
                                                        formValidationHelpers={props.formValidationHelpers}
                                                        officeWrapper={props.officeWrapper}
                                                        dateHelpers={props.dateHelpers}
                                                        onShowToast={showToast}
                                                        valueListApiServices={props.valueListApiServices}
                                                        selectedProject={selectedProject}
                                                        selectedHub={selectedHub}
                                                        showProgress={showProgress}
                                                        fileUploadApiService={props.fileUploadApiService}
                                                        submittalApiService={props.submittalApiService}
                                                        emailApiService={props.emailApiService}
                                                        relatedItemApiService={props.relatedItemApiService}
                                                        supportedAddinItems={supportedAddinItems}
                                                        bimProjectsApiService={props.bimProjectsApiService}
                                                        officeRoamingSettings={props.officeRoamingSettings}
                                                    />
                                                </WorkflowItemProvider>
                                            </Route>
                                            <Route exact path={ROUTE_PATH_NAMES.ISSUE}>
                                                <WorkflowItemProvider>
                                                    <IssueComponentWrapper
                                                        formValidationHelpers={props.formValidationHelpers}
                                                        officeWrapper={props.officeWrapper}
                                                        dateHelpers={props.dateHelpers}
                                                        onShowToast={showToast}
                                                        bimProjectsApiService={props.bimProjectsApiService}
                                                        selectedProject={selectedProject}
                                                        selectedHub={selectedHub}
                                                        showProgress={showProgress}
                                                        emailApiService={props.emailApiService}
                                                        relatedItemApiService={props.relatedItemApiService}
                                                        supportedAddinItems={supportedAddinItems}
                                                        officeRoamingSettings={props.officeRoamingSettings}
                                                        issueApiService={props.issueApiService}
                                                    />
                                                </WorkflowItemProvider>
                                            </Route>
                                        </>
                                    </SupportedFunctionalities>
                                </HubSelectorDashboard>
                            </FeatureFlagProvider>
                        </RedirectComponent>
                    </Switch>
                </HashRouter>
            </QueryClientProvider>
        </div>
    );
}

export default withLocalize(AppComponent);
