import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import ProjectEmailListItemComponent from "./ProjectEmailListItem/ProjectEmailListItemComponent";
import { InfiniteData } from "@tanstack/react-query";
import "./ProjectEmailComponent.less";
import { WithNextPage } from "../../services/PimTrackApi/types";
import { Email } from "@newforma/platform-client-api-sdk";
import { useAppState } from "../../store/AppProvider";
import { DateHelpers } from "../../helpers/DateHelpers";
import NoResultsFoundComponent from "./NoResultsFoundComponent/NoResultsFoundComponent";
import { useProjectEmailSearch } from "../../hooks/useProjectEmailSearch";
import ProjectEmailEmptyPageResult from "./EmptyPageResultComponent/EmptyPageResultComponent";
import { useProjectEmailViewer } from "../../hooks/useProjectEmailViewer";

export interface ProjectEmailListComponentProps extends LocalizeContextProps {
    displayEmptyPageResult: boolean;
    data: InfiniteData<WithNextPage<Email.SearchResponse>>;
}

function ProjectEmailListComponent(props: ProjectEmailListComponentProps) {
    const { displayEmptyPageResult, data, translate } = props;
    const { appState } = useAppState();
    const {
        states: { isFilterOn, query },
    } = useProjectEmailSearch();
    const { openEmailsViewer } = useProjectEmailViewer(props);

    if (displayEmptyPageResult) {
        return isFilterOn || query.length ? (
            <NoResultsFoundComponent />
        ) : (
            <ProjectEmailEmptyPageResult isProjectSelected={!!appState.selectedProject} />
        );
    }

    return (
        <>
            {data.pages.map((page) => (
                <React.Fragment key={`page-${page.paging.nextPage}`}>
                    {page.hits.map(({ from, displaySubject, body, receivedDate, messageId, hasAttachments }, key) => (
                        <ProjectEmailListItemComponent
                            key={`projectEmail-${key}`}
                            item={{
                                from: from || {
                                    email: "",
                                },
                                subject: displaySubject || (translate("PROJECT_EMAIL.NO_SUBJECT") as string),
                                body: body ? body[0] : "",
                                date: receivedDate ? DateHelpers.formatDate(receivedDate) : "",
                                messageId: messageId,
                                hasAttachment: hasAttachments,
                            }}
                            openEmailsViewer={openEmailsViewer}
                            supportedItems={appState.supportedAddinItems}
                        />
                    ))}
                </React.Fragment>
            ))}
        </>
    );
}

export default withLocalize(ProjectEmailListComponent);
