import { Email } from "@newforma/platform-client-api-sdk";
import { ProjectEmail } from "../components/projectEmail/ProjectEmailComponent";

export class ProjectEmailMapping {
    static mapToProjectEmail(email: Email.EmailDetails): ProjectEmail {
        return {
            from: {
                email: email.from?.email || "",
                name: email.from?.name || "",
            },
            subject: email.subject,
            body: Array.isArray(email.body) ? email.body.join(" ") : email?.body ? email.body : "",
            date: email.receivedDate || "",
            messageId: email.messageId,
            hasAttachment: email.hasAttachments,
        };
    }
}
