import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { CommandBar } from "office-ui-fabric-react";
import * as React from "react";
import "./ProjectEmailsViewerActions.less";
import useProjectEmailViewerActions from "../../../../hooks/useProjectEmailViewerActions";
import { ProjectEmail } from "../../ProjectEmailComponent";

export interface ProjectEmailsViewerActionsProps extends LocalizeContextProps {
    projectEmail: ProjectEmail;
}

function ProjectEmailsViewerActions(props: ProjectEmailsViewerActionsProps) {
    const { projectEmailViewerActions } = useProjectEmailViewerActions({ ...props, item: props.projectEmail });

    return (
        <div className="newforma-projectEmailViewerActions">
            <CommandBar items={projectEmailViewerActions} />
        </div>
    );
}

export default withLocalize(ProjectEmailsViewerActions);
