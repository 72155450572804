import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { getInitials } from "../../ProjectEmailListItem/ProjectEmailListItemComponent";
import "./ProjectEmailsViewerMetadataComponent.less";
import { Persona, PersonaSize } from "office-ui-fabric-react";
import EmailsViewerMetadataRecipientsComponent from "./ProjectEmailsViewerMetadataRecipientsComponent/ProjectEmailsViewerMetadataRecipientsComponent";
import { Email } from "@newforma/platform-client-api-sdk";
import { DateHelpers } from "../../../../helpers/DateHelpers";
import { getEmailDisplayString } from "../../../../utils/helpers";
import ProjectEmailsViewerActions from "../ProjectEmailsViewerActions/ProjectEmailsViewerActions";
import { ProjectEmailMapping } from "../../../../mapping/ProjectEmailMapping";
import { ExtendedEmailDetails } from "../../../../models/ProjectEmailViewer";

export interface ProjectEmailsViewerMetadataComponent extends LocalizeContextProps {
    email: ExtendedEmailDetails;
    toggleParticipantsExpanded: (messageId: string) => void;
    isExpanded: boolean;
}

function ProjectEmailsViewerMetadataComponent({
    email,
    toggleParticipantsExpanded,
    isExpanded,
    translate,
}: ProjectEmailsViewerMetadataComponent): JSX.Element {
    const recipientsList = (): Email.EmailContact[] => {
        const recipients = [];
        if (email.to) {
            recipients.push(...email.to);
        }
        if (email.cc) {
            recipients.push(...email.cc);
        }
        return recipients;
    };

    const toCcString = `${translate("PROJECT_EMAIL.TO") as string}/${translate("PROJECT_EMAIL.CC") as string}`;

    const dateString = (date: string) =>
        `${DateHelpers.formatDate(date)} ${translate("PROJECT_EMAIL.AT") as string} ${DateHelpers.formatTime(date)}`;

    const toggleExpandParticipantsList = () => {
        toggleParticipantsExpanded(email.messageId);
    };

    return (
        <div className="newforma-metadataContainer">
            <div className="newforma-metadataSubject">
                <span data-testid="newforma-metadataSubjectSpan" className="newforma-emailSubject">
                    {email.subject}
                </span>
                <ProjectEmailsViewerActions projectEmail={ProjectEmailMapping.mapToProjectEmail(email)} />
            </div>

            {email.from && email.sentDate && (
                <div className="newforma-metadata">
                    <div>
                        <Persona size={PersonaSize.size32} imageInitials={getInitials(email.from)}></Persona>
                    </div>
                    <div className="newforma-metadataDetails">
                        <div className="newforma-metadataFrom">{getEmailDisplayString(email.from)}</div>
                        <div className="newforma-metadataItem">
                            <span className="newforma-metadataLabel">{toCcString}</span>
                            <EmailsViewerMetadataRecipientsComponent
                                recipients={recipientsList()}
                                toggleParticipantsExpanded={toggleExpandParticipantsList}
                                isExpanded={isExpanded}
                            />
                        </div>
                        <div className="newforma-metadataItem">
                            <span className="newforma-metadataLabel">{translate("PROJECT_EMAIL.SENT_DATE")}</span>
                            <div className="newforma-sentDate">{dateString(email.sentDate ?? "")}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withLocalize(ProjectEmailsViewerMetadataComponent);
