export const EXPIRY_TIME_FOR_CACHE: number = 5;

export enum LaunchDarklyFeatureFlags {
    PROJECT_EMAILS_VIEWER = "outlook-addin-project-emails-viewer",
}

export const SubscriptionFunctionalities = {
    viewer3D: {
        access3dViewer: "DC7C3D37-B1F5-4D37-A845-D0142456C63C",
    },
    email: {
        emailManagement: "F4A0BF34-DBD0-4EB0-9816-2BE2F98FE8D9",
        noDeleteEmail: "C1C96C15-59CD-48F8-9DBF-EFFF5D415784",
    },
    rfi: {
        workflow: "76A6ED51-6F7C-40FE-B61A-09254BA2E217",
    },
    edmsConnector: {
        sharingData: "649BD28E-E95F-4C4A-ADCA-5AC4A7D29297",
    },
};

export enum ROUTE_PATH_NAMES {
    FILE_MULTIPLE_EMAIL = "/filemultipleemail",
    PROJECT_EMAIL = "/projectemail",
    ROOT = "/",
    RFI = "/rfi",
    SUBMITTAL = "/submittal",
    SEND_AND_FILE = "/sendandfile",
    ISSUE = "/issue",
    LOGIN_PATH = "/login",
}

export const INFO_TOAST_KEY = "newforma-info-message";

export interface InvalidBadRequests {
    id: string;
    error: string;
}

// prettier-ignore
export const INVALID_BAD_REQUEST_MESSAGES: InvalidBadRequests[] = [
    {
        id: "INVALID_REVIEWER_READER_TO",
        error: 'Reviewers/readers cannot be assigned to the \"to\" field of this workflow action.',
    },
    {
        id: "INVALID_REVIEWER_READER_FROM",
        error: 'Reviewers/readers cannot be assigned to the \"from\" field of this workflow action.',
    },
    {
        id: "INVALID_READER_TO",
        error: 'Readers cannot be assigned to the \"to\" field of this workflow action.',
    },
    {
        id: "INVALID_READER_FROM",
        error: 'Readers cannot be assigned to the \"from\" field of this workflow action.',
    },
    {
        id: "INVALID_REVIEWER_TO",
        error: 'Reviewers cannot be assigned to the \"to\" field of this workflow action.',
    },
    {
        id: "INVALID_REVIEWER_FROM",
        error: 'Reviewers cannot be assigned to the \"from\" field of this workflow action.',
    },
];
