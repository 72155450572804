import * as React from "react";
import { useEmailViewerAppState } from "./store/EmailsViewerAppProvider";
import EmailsViewerComponent from "./components/projectEmail/ProjectEmailsViewer/ProjectEmailsViewerComponent";
import { useAppService } from "./services/AppServiceProvider";
import { LocalStorageKeys } from "./models/StorageKeys";
import {
    ProjectEmailViewerMessage,
    ProjectEmailViewerMessageActions,
    ProjectEmailViewerParentActions,
    ProjectEmailViewerParentMessage,
    ProjectEmailViewerParentMessageData,
} from "./models/ProjectEmailViewer";
import { Email } from "@newforma/platform-client-api-sdk";
import { TranslationService } from "./services/TranslationService";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { useToast } from "./components/shared/toast/useToast";
import { MessageBarType } from "office-ui-fabric-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export interface EmailsViewerAppComponentProps extends LocalizeContextProps {
    translationService: TranslationService;
}

function EmailsViewerAppComponent(props: EmailsViewerAppComponentProps) {
    const {
        appState: { emails, selectedProject, selectedHub, selectedEmail },
        actions: { setEmails, setSelectedEmail, setSelectedProject, setSelectedHub },
    } = useEmailViewerAppState();
    const {
        services: { pimTrackEmailAPIService, configService, storageWrapper, officeWrapper, windowWrapper },
    } = useAppService();
    const { showToast } = useToast();
    const targetOrigin = { targetOrigin: configService.originURL };
    const { translationService, translate } = props;

    React.useEffect(() => {
        translationService.getTranslations(props);
        const message: ProjectEmailViewerMessage = {
            action: ProjectEmailViewerMessageActions.PROJECT_EMAIL_VIEWER_OPENED,
            data: {},
        };
        officeWrapper.contextUiMessageParent(JSON.stringify(message), targetOrigin);
        officeWrapper.contextUiAddHandlerAsync(
            Office.EventType.DialogParentMessageReceived,
            dialogParentMessageReceivedHandler
        );
    }, []);

    const sendOutgoingMessage = (message: ProjectEmailViewerMessage) => {
        officeWrapper.contextUiMessageParent(JSON.stringify(message), targetOrigin);
    };

    const dialogParentMessageReceivedHandler = (
        args: { message: string; origin: string | undefined } | { error: number }
    ) => {
        if ("origin" in args && args.origin !== configService.originURL) {
            return;
        }

        if ("error" in args) {
            return showErrorLoadingEmailToast();
        }

        processParentMessage(args.message);
    };

    const processParentMessage = (message: string) => {
        try {
            const parsedMessage: ProjectEmailViewerParentMessage = JSON.parse(message);
            const { action, data } = parsedMessage;

            if (action === ProjectEmailViewerParentActions.PROJECT_EMAIL_VIEWER_PARENT_UPDATED) {
                processProjectEmailsViewerParentUpdatedMessage(data);
            }
        } catch (error) {
            console.error("Failed to parse incoming message:", message, error);
        }
    };

    const processProjectEmailsViewerParentUpdatedMessage = (data: ProjectEmailViewerParentMessageData) => {
        if (data?.selectedEmail) {
            setSelectedEmail(data.selectedEmail);
        }
        if (data?.selectedProject) {
            setSelectedProject(data.selectedProject);
        }
        if (data?.selectedHub) {
            setSelectedHub(data.selectedHub);
        }
        if (data?.emails && data.emails.length > 0) {
            setEmails(data.emails);
        }
        if (data?.accessToken) {
            storageWrapper.saveLocalStorage(LocalStorageKeys.accessToken, data.accessToken);
        }
    };

    const getEmailAttachment = async (emailId: string, attachment: Email.EmailAttachmentDetails) => {
        if (!selectedProject || !selectedHub) {
            return showErrorDownloadingAttachmentToast();
        }

        try {
            const fileBlob = await pimTrackEmailAPIService.getAttachmentAsBlob(
                selectedHub,
                selectedProject,
                emailId,
                attachment.id
            );

            windowWrapper.downloadFile(fileBlob, attachment.name);
        } catch (error) {
            showErrorDownloadingAttachmentToast();
        }
    };

    const showErrorDownloadingAttachmentToast = () =>
        showToast(
            { message: translate("PROJECT_EMAIL.ERRORS.ERROR_DOWNLOADING_ATTACHMENT") as string },
            MessageBarType.error
        );

    const showErrorLoadingEmailToast = () =>
        showToast({ message: translate("PROJECT_EMAIL.ERRORS.ERROR_LOADING_EMAILS") as string }, MessageBarType.error);

    const queryClient = new QueryClient();

    return (
        <div className="newforma-appMain">
            <QueryClientProvider client={queryClient}>
                {selectedEmail && (
                    <EmailsViewerComponent
                        states={{
                            emails,
                            selectedEmail,
                        }}
                        actions={{
                            getEmailAttachment,
                            setEmails,
                            sendOutgoingMessage,
                            setSelectedEmail,
                        }}
                    />
                )}
            </QueryClientProvider>
        </div>
    );
}

export default withLocalize(EmailsViewerAppComponent);
