import { EmailDetails } from "@newforma/platform-client-api-sdk/src/email/Email";
import { ITag } from "office-ui-fabric-react";
import { IHub } from "./Hub";
import { ComposeType } from "./ComposeEmail";

export enum ProjectEmailViewerParentActions {
    PROJECT_EMAIL_VIEWER_PARENT_UPDATED = "ProjectEmailViewerParentUpdated",
}

export interface ProjectEmailViewerParentMessageData {
    accessToken?: string | null;
    selectedProject?: ITag;
    selectedHub?: IHub;
    emails?: ExtendedEmailDetails[];
    selectedEmail?: ExtendedEmailDetails;
}

export interface ProjectEmailViewerParentMessage {
    action: ProjectEmailViewerParentActions;
    data: ProjectEmailViewerParentMessageData;
}

export enum ProjectEmailViewerMessageActions {
    PROJECT_EMAIL_VIEWER_OPENED = "ProjectEmailViewerOpened",
    PROJECT_EMAIL_VIEWER_ACTION_CLICKED = "ProjectEmailViewerActionClicked",
    PROJECT_EMAIL_VIEWER_UPDATED = "ProjectEmailViewerUpdated",
    PROJECT_EMAIL_VIEWER_CLOSED = "ProjectEmailViewerClosed",
}

export interface ProjectEmailViewerMessageData {
    messageId?: string;
    composeType?: ComposeType;
    emails?: ExtendedEmailDetails[];
    selectedEmail?: ExtendedEmailDetails;
}

export interface ProjectEmailViewerMessage {
    action: ProjectEmailViewerMessageActions;
    data: ProjectEmailViewerMessageData;
}

export interface ExtendedEmailDetails extends EmailDetails {
    isParticipantsExpanded?: boolean;
    isAttachmentsExpanded?: boolean;
}
