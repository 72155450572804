import { MessageBarType } from "office-ui-fabric-react";
import { useAppState } from "../store/AppProvider";
import { useAppService } from "../services/AppServiceProvider";
import { ProjectEmail } from "../components/projectEmail/ProjectEmailComponent";
import { useToast } from "../components/shared/toast/useToast";
import { TranslateFunction } from "react-localize-redux";
import {
    ProjectEmailViewerMessage,
    ProjectEmailViewerMessageActions,
    ProjectEmailViewerMessageData,
    ProjectEmailViewerParentActions,
    ProjectEmailViewerParentMessage,
    ExtendedEmailDetails,
} from "../models/ProjectEmailViewer";
import { LocalStorageKeys } from "../models/StorageKeys";
import useComposeEmail from "./useComposeEmail";
import { useEffect, useRef } from "react";
import { isEqual } from "lodash";

export const MAX_TABS = 10;
export interface UseProjectEmailViewerProps {
    translate: TranslateFunction;
}

interface ProjectEmailViewerActions {
    openEmailsViewer: (projectEmail: ProjectEmail) => void;
}

export function useProjectEmailViewer(props: UseProjectEmailViewerProps): ProjectEmailViewerActions {
    const { appState, actions } = useAppState();
    const { selectedHub, selectedProject, projectEmailViewer } = appState;
    const { setProjectEmailViewer } = actions;
    const {
        services: { pimTrackEmailAPIService, configService, officeWrapper, storageWrapper },
    } = useAppService();
    const {
        actions: { composeNewEmail },
    } = useComposeEmail(props);
    const { showToast } = useToast();
    const prevProjectEmailViewerRef = useRef(projectEmailViewer);

    const dialogUrl = `${configService.originURL}/index.html#emailsviewerrouting`;
    const dialogOptions = {
        height: 50,
        width: 25,
        promptBeforeOpen: false,
    };

    const openEmailsViewer = async (projectEmail: ProjectEmail): Promise<void> => {
        if (!selectedHub || !selectedProject) {
            return showErrorLoadingEmailToast();
        }

        const emailDetails = await pimTrackEmailAPIService.fetchEmailDetails(
            selectedHub,
            selectedProject,
            projectEmail.messageId
        );

        if (!emailDetails) {
            return showErrorLoadingEmailToast();
        }

        if (projectEmailViewer.openedEmailViewer) {
            projectEmailViewer.openedEmailViewer.close();
        }

        const extendedEmailDetails: ExtendedEmailDetails = {
            ...emailDetails,
            isParticipantsExpanded: false,
            isAttachmentsExpanded: false,
        };

        setProjectEmailViewer({
            selectedEmail: extendedEmailDetails,
            emailList: removeDuplicateAndTrim([...projectEmailViewer.emailList, extendedEmailDetails]),
            openedEmailViewer: null,
        });
    };

    useEffect(() => {
        if (isEqual(prevProjectEmailViewerRef.current, projectEmailViewer)) {
            return;
        }

        if (!projectEmailViewer.openedEmailViewer && projectEmailViewer.selectedEmail) {
            officeWrapper.displayDialogAsync(dialogUrl, dialogOptions, dialogDisplayAsyncCallback);
        }

        if (prevProjectEmailViewerRef.current.openedEmailViewer === null && projectEmailViewer.openedEmailViewer) {
            projectEmailViewer.openedEmailViewer.addEventHandler(
                Office.EventType.DialogMessageReceived,
                dialogMessageReceivedHandler
            );
            projectEmailViewer.openedEmailViewer.addEventHandler(
                Office.EventType.DialogEventReceived,
                dispatchEventReceivedHandler
            );
        }

        prevProjectEmailViewerRef.current = projectEmailViewer;
    }, [projectEmailViewer]);

    const dialogDisplayAsyncCallback = (result: Office.AsyncResult<Office.Dialog>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
            const dialog = result.value;
            setProjectEmailViewer({
                ...projectEmailViewer,
                openedEmailViewer: dialog,
            });

            return;
        }

        if (result.status === Office.AsyncResultStatus.Failed && result.error.code === 12007) {
            showErrorDialogAlreadyOpenedToast();

            return;
        }

        showErrorLoadingEmailToast();
    };

    const dialogMessageReceivedHandler = async (
        args: { message: string; origin: string | undefined } | { error: number }
    ) => {
        if ("origin" in args && args.origin !== configService.originURL) {
            return;
        }

        if ("error" in args) {
            return showErrorLoadingEmailToast();
        }

        await processDialogMessageReceived(args.message);
    };

    const processDialogMessageReceived = async (message: string) => {
        try {
            const parsedMessage: ProjectEmailViewerMessage = JSON.parse(message);

            if (parsedMessage.action === ProjectEmailViewerMessageActions.PROJECT_EMAIL_VIEWER_OPENED) {
                processProjectEmailViewerOpenedMessage();
            }

            if (parsedMessage.action === ProjectEmailViewerMessageActions.PROJECT_EMAIL_VIEWER_UPDATED) {
                processProjectEmailViewerUpdatedMessage(parsedMessage.data);
            }

            if (parsedMessage.action === ProjectEmailViewerMessageActions.PROJECT_EMAIL_VIEWER_ACTION_CLICKED) {
                await processProjectEmailViewerActionClicked(parsedMessage.data);
            }
        } catch (error) {
            showErrorLoadingEmailToast();
        }
    };

    const processProjectEmailViewerActionClicked = async (data: ProjectEmailViewerMessageData) => {
        const { messageId, composeType } = data;

        if (!selectedHub || !selectedProject || !messageId || !composeType) {
            return showErrorCannotComposeEmailToast();
        }

        await composeNewEmail(selectedHub, selectedProject, messageId, composeType);
    };

    const processProjectEmailViewerOpenedMessage = () => {
        const accessToken = storageWrapper.loadLocalStorage(LocalStorageKeys.accessToken);

        if (
            !selectedHub ||
            !selectedProject ||
            !projectEmailViewer.selectedEmail ||
            !projectEmailViewer.openedEmailViewer ||
            !projectEmailViewer.emailList.length ||
            !accessToken
        ) {
            return showErrorLoadingEmailToast();
        }

        const messageToSend: ProjectEmailViewerParentMessage = {
            action: ProjectEmailViewerParentActions.PROJECT_EMAIL_VIEWER_PARENT_UPDATED,
            data: {
                selectedHub,
                selectedProject,
                accessToken,
                emails: projectEmailViewer.emailList,
                selectedEmail: projectEmailViewer.selectedEmail,
            },
        };
        projectEmailViewer.openedEmailViewer.messageChild(JSON.stringify(messageToSend));
    };

    const processProjectEmailViewerUpdatedMessage = (data: ProjectEmailViewerMessageData) => {
        // Update the emails state with the new data from the child window
        if (data?.emails && data.emails.length) {
            setProjectEmailViewer({
                ...projectEmailViewer,
                selectedEmail: data?.selectedEmail || null,
                emailList: data?.emails || [],
            });

            return;
        }

        // If emails are empty, close the dialog
        if (projectEmailViewer.openedEmailViewer) {
            projectEmailViewer.openedEmailViewer.close();
        }

        setProjectEmailViewer({
            selectedEmail: null,
            emailList: [],
            openedEmailViewer: null,
        });
    };

    const dispatchEventReceivedHandler = () => {
        if (projectEmailViewer.openedEmailViewer) {
            setProjectEmailViewer({
                selectedEmail: null,
                emailList: [],
                openedEmailViewer: null,
            });
        }
    };

    const showErrorLoadingEmailToast = () =>
        showToast(
            { message: props.translate("PROJECT_EMAIL.ERRORS.ERROR_LOADING_EMAILS") as string },
            MessageBarType.error
        );

    const showErrorDialogAlreadyOpenedToast = () =>
        showToast(
            { message: props.translate("PROJECT_EMAIL.ERRORS.DIALOG_ALREADY_OPENED") as string },
            MessageBarType.error
        );

    const showErrorCannotComposeEmailToast = () =>
        showToast(
            { message: props.translate("PROJECT_EMAIL.ERRORS.CANNOT_COMPOSE_EMAIL") as string },
            MessageBarType.error
        );

    const removeDuplicateAndTrim = (emails: ExtendedEmailDetails[]) => {
        // must reverse to avoid resetting states if the same email is opened
        const uniqueEmailsList = new Map([...emails].reverse().map((e) => [e.messageId, e]));

        return Array.from(uniqueEmailsList.values()).reverse().slice(-MAX_TABS);
    };

    return {
        openEmailsViewer,
    };
}
