import { Submittal } from "@newforma/platform-client-api-sdk";
import { Request } from "../Request";
import { ITag } from "office-ui-fabric-react";
import { IHub } from "../../models/Hub";
import { ConfigurationService } from "../ConfigurationService";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Logger } from "../Logger";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { BaseApiService, Method } from "./BaseApiService";
import { ProjectItemFeature } from "./types";

export class SubmittalApiService extends BaseApiService {
    constructor(
        private logger: Logger,
        protected newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private configService: ConfigurationService
    ) {
        super(newformaApiClient);
    }

    async logSubmittal(
        selectedHub: IHub,
        selectedProject: ITag,
        body: Submittal.LogRequest
    ): Promise<Submittal.LogResponse> {
        this.logger.info("Log a Rfi");
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildLogSubmittalEndpoint(domain, selectedHub.key.toString(), selectedProject.key.toString());

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Post), body: JSON.stringify(body) },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    getSubmittalUrl(submittalId: string, selectedHub: IHub, selectedProject: ITag): string {
        const bimUrl = new URL(this.configService.webAppURL);

        return new URL(
            `${bimUrl.protocol}//${selectedHub.name}.${bimUrl.hostname}/projects/${selectedProject.key}/${ProjectItemFeature.Submittals}/${submittalId}`
        ).toString();
    }

    async searchSubmittal(
        selectedHub: IHub,
        selectedProject: ITag,
        seachRequest: Submittal.SearchRequest
    ): Promise<Submittal.SearchResponse> {
        this.logger.info(`Search Submittal: ${seachRequest.query}`);
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildSearchSubmittalEndpoint(
            domain,
            selectedHub.key.toString(),
            selectedProject.key.toString()
        );

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Post), body: JSON.stringify(seachRequest) },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    async submitReviewResponse(
        selectedHub: IHub,
        selectedProject: ITag,
        submittalId: string,
        body: Submittal.ReviewResponseRequest
    ): Promise<Submittal.ReviewResponseResponse> {
        this.logger.info(`Submit Submittal review response: ${submittalId}`);
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildSubmittalReviewResponseEndpoint(
            domain,
            selectedHub.key.toString(),
            selectedProject.key.toString(),
            submittalId
        );

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Put), body: JSON.stringify(body) },
            async (signedOptions: Request) =>
                this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    async getSubmittalDetail(
        selectedHub: IHub,
        selectedProject: ITag,
        submittalId: string
    ): Promise<Submittal.DetailsResponse> {
        this.logger.info(`Get Submittal Detail: ${submittalId}`);
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);

        const url = this.buildSubmittalWithIdEndpoint(
            domain,
            selectedHub.key.toString(),
            selectedProject.key.toString(),
            submittalId
        );

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Get) },
            async (signedOptions: Request) =>
                this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers)
        );
    }

    private buildSubmittalWithIdEndpoint(
        domain: string,
        hubId: string,
        projectId: string,
        submittalId: string
    ): string {
        return new URL(
            `${this.basePimUrl(domain, hubId, projectId, ProjectItemFeature.Submittals)}/${submittalId}`
        ).toString();
    }

    private buildSubmittalReviewResponseEndpoint(
        domain: string,
        hubId: string,
        projectId: string,
        submittalId: string
    ): string {
        return new URL(
            `${this.buildSubmittalWithIdEndpoint(domain, hubId, projectId, submittalId)}/reviewresponse`
        ).toString();
    }

    private buildLogSubmittalEndpoint(domain: string, hubId: string, projectId: string): string {
        return new URL(`${this.basePimUrl(domain, hubId, projectId, ProjectItemFeature.Submittals)}/log`).toString();
    }

    private buildSearchSubmittalEndpoint(domain: string, hubId: string, projectId: string): string {
        return new URL(`${this.basePimUrl(domain, hubId, projectId, ProjectItemFeature.Submittals)}/search`).toString();
    }
}
