import { OfficeWrapper } from "./OfficeWrapper";
import { Logger } from "./Logger";
import { WindowWrapper } from "./WindowWrapper";
import { FileWithId } from "../models/shared/FileWithId";
import { AttachmentDetailsMetadata } from "../models/AttachmentDetailsMetadata";
import AttachmentDetails = Office.AttachmentDetails;
import { MsGraphApiService } from "./MsGraphApiService";
import { MsGraphEmailAttachmentOdataType } from "../models/MsGraphGetEmailAttachmentResponse";

export enum OutlookItemMimeType {
    email = "message/rfc822",
    contact = "text/vcard",
    event = "text/calendar",
}

export interface AttachmentContent {
    blob: Blob;
    contentType: string;
    extension: string;
    name: string;
}

export class OutlookApiService {
    constructor(
        private officeWrapper: OfficeWrapper,
        private windowWrapper: WindowWrapper,
        private msGraphApiService: MsGraphApiService,
        private logger: Logger
    ) {}

    async getFileAttachmentDetailsMetadata(
        attachments: (AttachmentDetails | FileWithId)[]
    ): Promise<AttachmentDetailsMetadata[]> {
        let noFileNameIndex = 0;
        const promises = attachments.map(async (attachment) => {
            if (this.isAttachmentDetails(attachment)) {
                const attachmentContent = await this.getEmailAttachmentContent(
                    attachment.id,
                    attachment.attachmentType
                );

                const extension = attachment.name.split(".").pop();

                return {
                    id: attachment.id,
                    fileName: attachmentContent.name || `_[${noFileNameIndex++}]${extension}`,
                    file: attachmentContent.blob,
                };
            }
            const file = attachment as FileWithId;
            return {
                id: attachment.id,
                fileName: attachment.name,
                file: file.file,
            };
        });

        return Promise.all(promises);
    }

    private async getEmailAttachmentContent(
        attachmentId: string,
        attachmentType: Office.MailboxEnums.AttachmentType | string
    ): Promise<AttachmentContent> {
        this.logger.info("Retrieving email attachment bytes from Microsoft Graph");

        if (attachmentType === Office.MailboxEnums.AttachmentType.Item) {
            const rawContent = await this.msGraphApiService.getAttachmentRawValue(
                this.officeWrapper.currentContextItem.itemId,
                attachmentId
            );

            const attachmentDetails = await this.msGraphApiService.getAttachmentItemTypeExpandedDetails(
                this.officeWrapper.currentContextItem.itemId,
                attachmentId
            );

            const attachmentItemType = attachmentDetails.item["@odata.type"];
            const contentType = this.getAttachmentItemTypeMimeType(attachmentItemType);
            const extension = this.getAttachmentItemTypeExtension(attachmentItemType);

            return {
                blob: this.getBlobFromBase64EncodedByteString(rawContent, contentType),
                contentType,
                extension,
                name: `${attachmentDetails.name}${extension}`,
            };
        }

        const result = await this.msGraphApiService.getAttachment(
            this.officeWrapper.currentContextItem.itemId,
            attachmentId
        );

        return {
            blob: this.getBlobFromBase64EncodedByteString(result.contentBytes, result.contentType),
            contentType: result.contentType,
            extension: result.name.split(".").pop() || "",
            name: result.name,
        };
    }

    private getAttachmentItemTypeExtension(attachmentItemType: string): string {
        switch (attachmentItemType) {
            case MsGraphEmailAttachmentOdataType.Message:
                return ".eml";
            case MsGraphEmailAttachmentOdataType.Contact:
                return ".vcf";
            case MsGraphEmailAttachmentOdataType.Event:
                return ".ics";
            default: {
                return ".eml";
            }
        }
    }

    private getAttachmentItemTypeMimeType(attachmentItemType: MsGraphEmailAttachmentOdataType) {
        switch (attachmentItemType) {
            case MsGraphEmailAttachmentOdataType.Message:
                return OutlookItemMimeType.email;
            case MsGraphEmailAttachmentOdataType.Contact:
                return OutlookItemMimeType.contact;
            case MsGraphEmailAttachmentOdataType.Event:
                return OutlookItemMimeType.event;
            default:
                return OutlookItemMimeType.email;
        }
    }

    private getBlobFromBase64EncodedByteString(fileBytes: string, mimeType: string): Blob {
        let decodedBytes: string;
        try {
            decodedBytes = this.windowWrapper.base64Decode(fileBytes);
        } catch {
            decodedBytes = fileBytes;
        }
        const binaryLength = decodedBytes.length;
        const blobParts = new Uint8Array(binaryLength);
        for (let i = 0; i < binaryLength; i++) {
            blobParts[i] = decodedBytes.charCodeAt(i);
        }
        return new Blob([blobParts], { type: mimeType });
    }

    private isAttachmentDetails(attachment: any): attachment is AttachmentDetails {
        return !!attachment.attachmentType;
    }
}
