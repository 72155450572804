import moment from 'moment-timezone';
import frenchLocaleSpecification from './localeSpecifications/fr';
import { momentRange } from '../dateTime/DateTimeRange';

export default class LocaleSettings {
  public static setupLocaleSpecifications(): void {
    moment.updateLocale('fr', frenchLocaleSpecification);
    momentRange.updateLocale('fr', frenchLocaleSpecification);
  }

  public static getCurrentTimeZone(): string {
    return moment().format('z');
  }

  public static getWellKnownTimeZoneFrom(timezone: string): string {
    return moment().tz(timezone).zoneName();
  }

  public static setCurrentTimeZone(timezone?: string): void {
    moment.tz.setDefault(timezone);
    momentRange.tz.setDefault(timezone);
  }

  public static getCurrentLocale(): string {
    return moment.locale();
  }

  public static setCurrentLocale(language: string): void {
    moment.locale(language);
    momentRange.locale(language);
  }

  public static get calendarStartDay(): number {
    return moment.localeData().firstDayOfWeek();
  }
}
