import { IOverflowSetItemProps } from "office-ui-fabric-react";
import { ProjectEmailActionsProps } from "../components/projectEmail/ProjectEmailActions/ProjectEmailActions";
import useComposeEmail from "./useComposeEmail";
import { ComposeType } from "../models/ComposeEmail";
import { useAppState } from "../store/AppProvider";

const useProjectEmailActions = (props: ProjectEmailActionsProps) => {
    const { appState } = useAppState();
    const {
        actions: { composeNewEmail },
        states: { isComposingNewEmail },
    } = useComposeEmail({
        translate: props.translate,
    });

    const handleActionClick = async (composeType: ComposeType, e: Event) => {
        e.stopPropagation();
        if (!appState.selectedHub || !appState.selectedProject) {
            return;
        }
        await composeNewEmail(appState.selectedHub, appState.selectedProject, props.item.messageId, composeType);
    };

    const emailActionReply: IOverflowSetItemProps[] = [
        {
            key: "reply",
            icon: "Reply",
            className: "reply-button",
            onClick: (e: Event) => handleActionClick(ComposeType.Reply, e),
        },
    ];

    const emailActionsMore = [
        {
            key: "replyAll",
            iconProps: {
                iconName: "replyAll",
                className: "overflowItem-icon",
            },
            name: props.translate("PROJECT_EMAIL.ACTION.REPLY_ALL") as string,
            onClick: (e: Event) => handleActionClick(ComposeType.ReplyAll, e),
            className: "overflowItemsWrapper extra-padding-top",
            disabled: isComposingNewEmail,
        },
        {
            key: "forward",
            iconProps: {
                iconName: "replyMirrored",
                className: "overflowItem-icon",
            },
            name: props.translate("PROJECT_EMAIL.ACTION.FORWARD") as string,
            onClick: (e: Event) => handleActionClick(ComposeType.Forward, e),
            className: "overflowItemsWrapper extra-padding-bottom",
            disabled: isComposingNewEmail,
        },
    ];

    return {
        isActionButtonDisabled: isComposingNewEmail,
        emailActionReply,
        emailActionsMore,
    };
};

export default useProjectEmailActions;
