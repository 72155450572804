import * as React from "react";
import { Logger } from "./Logger";
import { createContext, useContext, useMemo } from "react";
import { EmailApiService } from "./PimTrackApi/EmailApiService";
import { ConfigurationService } from "./ConfigurationService";
import { Authenticator } from "./Authenticator";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { NewformaApiClient } from "./NewformaApi/NewformaApiClient";
import { SubscriptionApiService } from "./SubscriptionApiService/SubscriptionApiService";
import { StorageWrapper } from "./StorageWrapper";
import { OfficeNotificationService } from "./officeUi/OfficeNotificationService";
import { OfficeWrapper } from "./OfficeWrapper";
import { ValueListApiService } from "./PimTrackApi/ValueListApiService";
import { BimProjectsApiService } from "./BimApi/BimProjectsApiService";
import { MsGraphApiService } from "./MsGraphApiService";
import { GraphRequestWrapper } from "./GraphRequestWrapper";
import { MsGraphTokenService } from "./MsGraphTokenService";
import { WindowWrapper } from "./WindowWrapper";

export interface AppServiceProviderState {
    services: {
        pimTrackEmailAPIService: EmailApiService;
        subscriptionApiService: SubscriptionApiService;
        configService: ConfigurationService;
        officeNotificationService: OfficeNotificationService;
        logger: Logger;
        officeWrapper: OfficeWrapper;
        valueListApiService: ValueListApiService;
        bimProjectsApiService: BimProjectsApiService;
        msGraphApiService: MsGraphApiService;
        windowWrapper: WindowWrapper;
        storageWrapper: StorageWrapper;
    };
}

export interface AppServiceProviderProps {
    logger: Logger;
    authenticator: Authenticator;
    configurationService: ConfigurationService;
    children: React.ReactNode;
}

const AppServiceContext = createContext<AppServiceProviderState | null>(null);
export const AppServiceProvider = (props: AppServiceProviderProps) => {
    const appProviderValue: AppServiceProviderState = useMemo(() => {
        const requestWrapper = new HttpRequestWrapper();
        const storageWrapper = new StorageWrapper(props.logger);
        const apiClient = new NewformaApiClient(props.configurationService, props.authenticator, props.logger);

        const subscriptionApiService = new SubscriptionApiService(
            props.logger,
            props.configurationService,
            apiClient,
            requestWrapper,
            storageWrapper
        );

        const pimTrackEmailAPIService = new EmailApiService(apiClient, requestWrapper);
        const officeNotificationService = new OfficeNotificationService();
        const officeWrapper = new OfficeWrapper(props.logger);
        const valueListApiService = new ValueListApiService(
            props.logger,
            apiClient,
            requestWrapper,
            props.configurationService
        );
        const bimProjectsApiService = new BimProjectsApiService(
            props.logger,
            props.configurationService,
            apiClient,
            requestWrapper
        );

        const msGraphTokenService = new MsGraphTokenService(
            storageWrapper,
            apiClient,
            requestWrapper,
            props.logger,
            props.configurationService
        );
        const graphRequestWrapper = new GraphRequestWrapper(requestWrapper, msGraphTokenService, props.logger);
        const msGraphApiService = new MsGraphApiService(officeWrapper, graphRequestWrapper, props.logger);
        const windowWrapper = new WindowWrapper(props.logger);

        return {
            services: {
                bimProjectsApiService,
                pimTrackEmailAPIService,
                subscriptionApiService,
                configService: props.configurationService,
                officeNotificationService,
                logger: props.logger,
                valueListApiService,
                officeWrapper,
                msGraphApiService,
                windowWrapper,
                storageWrapper,
            },
        };
    }, [props.logger, props.authenticator]);

    return <AppServiceContext.Provider value={appProviderValue}>{props.children}</AppServiceContext.Provider>;
};

export function useAppService(): AppServiceProviderState {
    const contextData = useContext(AppServiceContext);

    if (!contextData) {
        throw new Error("The AppServiceProvider component does not seem to have been used in the React component tree");
    }
    return useMemo(() => ({ ...contextData }), [contextData]);
}
