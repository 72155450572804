import {
    CommandBar,
    ContextualMenuItemType,
    DefaultButton,
    Dropdown,
    ICommandBarItemProps,
    IContextualMenuItem,
    IDropdownOption,
    Label,
    MessageBarType,
    ResponsiveMode,
    Separator,
} from "office-ui-fabric-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Redirect } from "react-router-dom";
import { AppPage } from "../../models/AppPage";
import { LocalStorageKeys } from "../../models/StorageKeys";
import { BimProjectsApiService } from "../../services/BimApi/BimProjectsApiService";
import { ConfigurationService } from "../../services/ConfigurationService";
import { Logger } from "../../services/Logger";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { StorageWrapper } from "../../services/StorageWrapper";
import { WindowWrapper } from "../../services/WindowWrapper";
import { useAppState } from "../../store/AppProvider";
import { ROUTE_PATH_NAMES } from "../../utils/constants";
import HubIcon from "../shared/icons/HubIcon";
import HubSwitchIcon from "../shared/icons/HubSwitchIcon";
import ProjectPickerComponent from "../shared/projectPicker/ProjectPickerComponent";
import SettingsComponent from "../shared/settings/SettingsComponent";
import "./AppHeaderComponent.less";
import { ToastMessage } from "../../models/ToastMessage";
import { useAppService } from "../../services/AppServiceProvider";

export interface AppHeaderProps extends LocalizeContextProps {
    officeRoamingSettings: OfficeRoamingSettings;
    officeWrapper: OfficeWrapper;
    windowWrapper: WindowWrapper;
    storageWrapper: StorageWrapper;
    smartFilingManager: SmartFilingManager;
    logger: Logger;
    configService: ConfigurationService;
    bimProjectsApiService: BimProjectsApiService;
    isFilingOptionSupportedOnSelectedScreen: boolean;
    handleDiagnostic: () => Promise<string>;
    onLogOut: () => void;
    onShowToast: (message: ToastMessage | null, type: MessageBarType) => void;
}

export interface AppHeaderState {
    hideApplicationSettingsDialog: boolean;
    showSettingsPanel: boolean;
    showSearchPanel: boolean;
    showProjectTeamPanel: boolean;
    redirectTo: string | null;
    emptyHubs: boolean;
}

function AppHeaderComponent(props: AppHeaderProps) {
    const { appState, actions } = useAppState();
    const {
        services: { officeWrapper },
    } = useAppService();

    const { locationPath, isLoggedIn, selectedHub, selectedProject, mailboxItem, supportedAddinItems, hubs } = appState;

    const {
        setNavigationPage,
        setDeleteEmailAfterFilingSetting,
        setFileEmailConversationSetting,
        handleSelectedProject,
        setSelectedHub,
        handleLocationPath,
    } = actions;

    const [state, setState] = useState<AppHeaderState>({
        hideApplicationSettingsDialog: true,
        showSettingsPanel: false,
        showSearchPanel: false,
        showProjectTeamPanel: false,
        redirectTo: null,
        emptyHubs: hubs?.length === 0,
    });

    useEffect(() => {
        props.officeWrapper.addHandlerAsync(Office.EventType.ItemChanged, itemChanged);
    }, []);

    const itemChanged = async (_eventArgs: any): Promise<void> => {
        props.logger.info("Selected mail item changed");

        if (_eventArgs.type !== "olkItemSelectedChanged" || !mailboxItem) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            showSettingsPanel: false,
            showSearchPanel: false,
        }));
    };

    const onSettingsChanged = (): void => {
        const deleteAfterFiling = props.officeRoamingSettings.getDeleteEmailAfterFiling();
        const fileConversation = props.officeRoamingSettings.getFileEmailConversation();

        setDeleteEmailAfterFilingSetting(deleteAfterFiling);
        setFileEmailConversationSetting(fileConversation);
    };

    const openSettingsClicked = () => {
        props.logger.info("Settings panel opened");
        setState((prevState) => ({
            ...prevState,
            showSettingsPanel: true,
        }));
    };

    const closeSettingsClicked = () => {
        props.logger.info("Settings panel closed");
        setState((prevState) => ({
            ...prevState,
            showSettingsPanel: false,
        }));
    };

    const signOutClicked = async (): Promise<void> => {
        await props.officeRoamingSettings.logoutFromApp();
        setState((prevState) => ({
            ...prevState,
            hideApplicationSettingsDialog: true,
        }));
        props.onLogOut();
    };

    const changeHubsItem = (): IContextualMenuItem => {
        const handleHubChange = async () => {
            handleSelectedProject(null);
            setSelectedHub(null);
            props.storageWrapper.removeLocalStorageItem(LocalStorageKeys.hubSubscriptionInfo);
        };

        const isClient = window.innerWidth > 320 && window.innerWidth <= 400 ? "width-client" : "width-browser";

        const ChangeHubsItemContent = () => (
            <DefaultButton className="change-hubs-item-content" onClick={() => handleHubChange()}>
                <div className="change-hubs-item-content-sub left">
                    <div className="change-hubs-item-content-logo">
                        <HubIcon />
                    </div>
                    <div className={`change-hubs-item-content-label ${isClient}`}>{selectedHub?.name}</div>
                </div>
                <div className="change-hubs-item-content-sub right">
                    <div className="change-hubs-item-switch-logo">
                        <HubSwitchIcon />
                    </div>
                    <div className="change-hubs-item-content-label-sub">
                        {props.translate("APP.COMMANDBAR.CHANGE_HUB")}
                    </div>
                </div>
            </DefaultButton>
        );

        return {
            key: "changeHubsItem",
            name: selectedHub?.name,
            ["data-automation-id"]: "openBIMTrackButton",
            target: "_blank",
            className: "app-header-item-large",
            onRender: () => <ChangeHubsItemContent />,
        };
    };

    const openBIMTrackItem = (): IContextualMenuItem => ({
        key: "openBIMTrack",
        name: props.translate("APP.COMMANDBAR.OPEN_BIMTRACK_WEB_APP") as string,
        iconProps: {
            iconName: "OpenInNewWindow",
        },
        ["data-automation-id"]: "openBIMTrackButton",
        title: props.translate("APP.COMMANDBAR.OPEN_BIMTRACK_WEB_APP_TOOLTIP") as string,
        href: props.configService.webAppURL,
        target: "_blank",
        className: "app-header-item",
    });

    const openHelpAndSupportItem = (): IContextualMenuItem => ({
        key: "openHelpAndSupportItem",
        name: props.translate("APP.COMMANDBAR.HELP_AND_SUPPORT") as string,
        iconProps: {
            iconName: "SurveyQuestions",
        },
        ["data-automation-id"]: "openHelpAndSupportItem",
        title: props.translate("APP.COMMANDBAR.HELP_AND_SUPPORT") as string,
        href: props.translate("URLS.HELP_AND_SUPPORT_OVERVIEW") as string,
        target: "_blank",
        className: "app-header-item",
    });

    const privacyPolicyMenuItem = (): IContextualMenuItem => ({
        key: "privacyPolicy",
        name: props.translate("APP.COMMANDBAR.PRIVACY_POLICY") as string,
        iconProps: {
            iconName: "PageLock",
        },
        href: props.translate("URLS.PRIVACY_POLICY") as string,
        target: "_blank",
        className: "app-header-item",
    });

    const termsOfUseMenuItem = (): IContextualMenuItem => ({
        key: "termsOfUse",
        name: props.translate("APP.COMMANDBAR.TERMS_OF_USE") as string,
        iconProps: {
            iconName: "TextDocument",
        },
        href: props.translate("URLS.TERMS_OF_USE") as string,
        target: "_blank",
        className: "app-header-item",
    });

    const dividerMenuItem = (keyIndex: number): IContextualMenuItem => ({
        key: `divider-${keyIndex}`,
        itemType: ContextualMenuItemType.Divider,
    });

    const settingsMenuItem = (): IContextualMenuItem => ({
        key: "settings",
        name: props.translate("APP.COMMANDBAR.SETTINGS") as string,
        iconProps: {
            iconName: "Settings",
        },
        onClick: openSettingsClicked,
        className: "app-header-item",
    });

    const logOutMenuItem = (): IContextualMenuItem => ({
        key: "logOut",
        name: props.translate("APP.COMMANDBAR.SIGN_OUT") as string,
        iconProps: {
            iconName: "Unlock",
        },
        onClick: signOutClicked as () => void,
        className: "app-header-item-large",
    });

    const getOptionsMenuItemsAuthenticated = (): IContextualMenuItem[] => {
        const menuItems: IContextualMenuItem[] = [];

        menuItems.push(changeHubsItem());
        menuItems.push(dividerMenuItem(1));
        menuItems.push(openBIMTrackItem());
        menuItems.push(settingsMenuItem());
        menuItems.push(openHelpAndSupportItem());
        menuItems.push(privacyPolicyMenuItem());
        menuItems.push(termsOfUseMenuItem());
        menuItems.push(dividerMenuItem(2));
        menuItems.push(logOutMenuItem());

        return menuItems;
    };

    const renderSupportedFilingItemsDropdown = (): ICommandBarItemProps => ({
        key: "label",
        onRender: () => (
            <div className="headerLabel-wrapper">
                {state.redirectTo && <Redirect to={state.redirectTo} />}
                {!state.emptyHubs && renderSupportedFilingItemsDropdownLabel()}
            </div>
        ),
    });

    const isRouteFileAndSend = (routeString: string | null): boolean =>
        ["/sendandfile", "sendandfile"].includes(routeString || "");

    const renderSupportedFilingItemsDropdownLabel = () => {
        if (isRouteFileAndSend(locationPath)) {
            return (
                <Label id="AppFilingOptionLabel" className="navHeader">
                    {props.translate("SEND_AND_FILE.LABEL")}
                </Label>
            );
        }

        const options = getSupportedFilingItems();
        if (options.length === 1) {
            return (
                <Label id="AppFilingOptionLabel" className="navHeader font-size-big">
                    {options[0].text}
                </Label>
            );
        }

        return (
            <Dropdown
                className={"navHeader"}
                options={getSupportedFilingItems()}
                responsiveMode={ResponsiveMode.large}
                onChange={onFilingOptionChange}
                defaultSelectedKey={getDefaultSelectedKey()}
            />
        );
    };

    const getDefaultSelectedKey = (): AppPage => {
        switch (state.redirectTo) {
            case ROUTE_PATH_NAMES.ROOT || ROUTE_PATH_NAMES.FILE_MULTIPLE_EMAIL:
                return AppPage.FileMultipleEmail;
            case ROUTE_PATH_NAMES.ISSUE:
                return AppPage.FileAsIssue;
            case ROUTE_PATH_NAMES.SUBMITTAL:
                return AppPage.FileAsSubmittal;
            case ROUTE_PATH_NAMES.RFI:
                return AppPage.FileAsRfi;
            case ROUTE_PATH_NAMES.PROJECT_EMAIL:
                return AppPage.ProjectEmail;
            default:
                return AppPage.FileMultipleEmail;
        }
    };

    const onFilingOptionChange = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (!item) {
            setState((prevState) => ({ ...prevState, redirectTo: null }));
            return;
        }

        setNavigationPage(item.key as AppPage);
        switch (item.key) {
            case AppPage.FileMultipleEmail:
                handleLocationPath(ROUTE_PATH_NAMES.ROOT);
                setState((prevState) => ({ ...prevState, redirectTo: ROUTE_PATH_NAMES.ROOT }));
                return;
            case AppPage.ProjectEmail:
                handleLocationPath(ROUTE_PATH_NAMES.PROJECT_EMAIL);
                setState((prevState) => ({ ...prevState, redirectTo: ROUTE_PATH_NAMES.PROJECT_EMAIL }));
                return;
            case AppPage.FileAsIssue:
                handleLocationPath(ROUTE_PATH_NAMES.ISSUE);
                setState((prevState) => ({ ...prevState, redirectTo: ROUTE_PATH_NAMES.ISSUE }));
                return;
            case AppPage.FileAsSubmittal:
                handleLocationPath(ROUTE_PATH_NAMES.SUBMITTAL);
                setState((prevState) => ({ ...prevState, redirectTo: ROUTE_PATH_NAMES.SUBMITTAL }));
                return;
            case AppPage.FileAsRfi:
                handleLocationPath(ROUTE_PATH_NAMES.RFI);
                setState((prevState) => ({ ...prevState, redirectTo: ROUTE_PATH_NAMES.RFI }));
                return;
        }
    };

    const getSupportedFilingItems = (): IDropdownOption[] => {
        const options: IDropdownOption[] = [];

        if (!supportedAddinItems) {
            return options;
        }

        const { canUseEmail, canUseWorkflow, canUseIssues, canUseProjectEmailsViewer } = supportedAddinItems;

        if (canUseEmail) {
            options.push({
                key: AppPage.FileMultipleEmail,
                text: props.translate("APP.FILE_EMAIL_HEADER") as string,
            });
        }

        if (canUseWorkflow) {
            options.push({
                key: AppPage.FileAsRfi,
                text: props.translate("APP.FILE_AS_RFI_HEADER") as string,
            });

            options.push({
                key: AppPage.FileAsSubmittal,
                text: props.translate("APP.FILE_AS_SUBMITTAL_HEADER") as string,
            });
        }

        if (canUseIssues) {
            options.push({
                key: AppPage.FileAsIssue,
                text: props.translate("APP.FILE_ISSUE_HEADER") as string,
            });
        }

        if (!officeWrapper.isMobileDevice()) {
            options.push({
                key: AppPage.ProjectEmail,
                text: props.translate("APP.PROJECT_EMAIL_HEADER") as string,
            });
        }

        return options;
    };

    const renderAppOptionsDropdown = (): ICommandBarItemProps[] => {
        const commandBarProps = [];

        const overflowMenuItem = {
            key: "menuOptions",
            menuIconProps: {
                iconName: "More",
            },
            iconOnly: true,
            ["data-automation-id"]: "overflowMenuButton",
            id: "AppOptionsDropdown",
            subMenuProps: {
                items: isLoggedIn ? getOptionsMenuItemsAuthenticated() : [],
            },
        };

        commandBarProps.push(overflowMenuItem);
        return commandBarProps;
    };

    const hideCommandBarItems: boolean = !!(
        isLoggedIn &&
        selectedHub &&
        (!props.isFilingOptionSupportedOnSelectedScreen ||
            (supportedAddinItems?.isSubscriptionExpired && supportedAddinItems?.isSubscriptionExpired === true))
    );

    if (!selectedHub) {
        return null;
    }

    if (hideCommandBarItems) {
        return <CommandBar id="CommandBar" items={[]} farItems={renderAppOptionsDropdown()} />;
    }

    return (
        <div data-testid="AppHeader" id="AppHeader">
            {isLoggedIn && (
                <CommandBar
                    id="CommandBar"
                    items={[renderSupportedFilingItemsDropdown()]}
                    farItems={renderAppOptionsDropdown()}
                />
            )}

            {isLoggedIn && !state.emptyHubs && isRouteFileAndSend(locationPath) && (
                <Label className="newforma-send-and-file-header">
                    {props.translate("SEND_AND_FILE.HINT_TEXT") as string}
                </Label>
            )}

            {isLoggedIn && !state.emptyHubs && (
                <ProjectPickerComponent
                    logger={props.logger}
                    smartFilingManager={props.smartFilingManager}
                    onExpiredSession={props.onLogOut}
                    bimProjectsApiService={props.bimProjectsApiService}
                    hub={selectedHub}
                    onProjectSelected={handleSelectedProject}
                    selectedProject={selectedProject}
                    disabled={false}
                    projects={[]}
                />
            )}

            <Separator />

            <SettingsComponent
                handleDiagnostic={props.handleDiagnostic}
                windowWrapper={props.windowWrapper}
                configService={props.configService}
                onDismiss={closeSettingsClicked}
                showSettingsPanel={state.showSettingsPanel}
                officeRoamingSettings={props.officeRoamingSettings}
                officeWrapper={props.officeWrapper}
                logger={props.logger}
                onSettingsChanged={onSettingsChanged}
                storageWrapper={props.storageWrapper}
                setSelectedHub={setSelectedHub}
                supportedAddinItems={supportedAddinItems}
            />
        </div>
    );
}

export default withLocalize(AppHeaderComponent);
