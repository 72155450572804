import { Email } from "@newforma/platform-client-api-sdk";
import { Request } from "../Request";
import { ITag } from "office-ui-fabric-react";
import { IHub } from "../../models/Hub";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { NewformaApiClient } from "../NewformaApi/NewformaApiClient";
import { BaseApiService, Method } from "./BaseApiService";
import { ProjectItemFeature, WithNextPage } from "./types";
import { PagingRequest } from "@newforma/platform-client-api-sdk/src/common/Paging";

export const SEARCH_REQUEST_HIGHLIGHT = {
    openDelimiter: '<em class="pimtrack-highlight">',
    closeDelimiter: "</em>",
    encoding: "html",
};

interface SearchEmailsProps {
    selectedHub: IHub;
    selectedProject: ITag;
    query: string;
    pageNumber: number;
    filter?: Email.SearchRequestFilters;
}

export class EmailApiService extends BaseApiService {
    private emailListPageSize = 50;

    constructor(protected newformaApiClient: NewformaApiClient, private requestWrapper: HttpRequestWrapper) {
        super(newformaApiClient);
    }

    async searchEmails({
        selectedHub,
        selectedProject,
        query,
        pageNumber,
        filter,
    }: SearchEmailsProps): Promise<WithNextPage<Email.SearchResponse>> {
        const domain = this.newformaApiClient.mapDomainRegion(selectedHub);
        const url = this.buildSearchEmailEndpoint(domain, selectedHub.key.toString(), selectedProject.key.toString());
        const paging: PagingRequest = { pageNumber, pageSize: this.emailListPageSize };

        const res: Email.SearchResponse = await this.newformaApiClient.makeRequest(
            {
                ...this.getOptions(url, Method.Post),
                body: JSON.stringify({ query: query, filter, paging, highlight: SEARCH_REQUEST_HIGHLIGHT }),
            },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );

        const totalPages = Math.ceil(res.paging.totalCount / this.emailListPageSize);

        return {
            hits: res.hits,
            paging: {
                totalCount: res.paging.totalCount,
                nextPage: totalPages === pageNumber || totalPages === 0 ? undefined : pageNumber + 1,
            },
        };
    }

    async fetchEmailDetails(hub: IHub, project: ITag, internetMessageId: string): Promise<Email.EmailDetails> {
        const domain = this.newformaApiClient.mapDomainRegion(hub);

        const url = `${this.buildSearchEmailEndpoint(domain, hub.key.toString(), project.key.toString())}/details`;

        const body = { query: "", messageId: internetMessageId };

        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Post), body: JSON.stringify(body) },
            async (signedOptions: Request) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private buildSearchEmailEndpoint(domain: string, hubId: string, projectId: string): string {
        return new URL(`${this.basePimUrl(domain, hubId, projectId, ProjectItemFeature.Emails)}/search`).toString();
    }

    private buildAttachmentInfoEndpoint(
        domain: string,
        hubId: string,
        projectId: string,
        emailId: string,
        attachmentId: string
    ): string {
        return new URL(
            `${this.basePimUrl(
                domain,
                hubId,
                projectId,
                ProjectItemFeature.Emails
            )}/${emailId}/attachments/${attachmentId}`
        ).toString();
    }

    async getAttachmentAsBlob(hub: IHub, project: ITag, emailId: string, attachmentId: string): Promise<Blob> {
        const domain = this.newformaApiClient.mapDomainRegion(hub);

        const url = this.buildAttachmentInfoEndpoint(
            domain,
            hub.key.toString(),
            project.key.toString(),
            emailId,
            attachmentId
        );

        const downloadInfo: { url: string } = await this.getAttachmentDownloadLink(url);
        const downloadLink: string = downloadInfo.url;
        return this.fetchBlob(downloadLink);
    }

    async getAttachmentDownloadLink(url: string): Promise<{ url: string }> {
        return this.newformaApiClient.makeRequest(
            { ...this.getOptions(url, Method.Get) },
            async (signedOptions: Request) =>
                this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    async fetchBlob(url: string): Promise<Blob> {
        try {
            return await this.requestWrapper.get(url, undefined, undefined, undefined, {
                xhrFields: { responseType: "blob" },
            });
        } catch (error) {
            throw new Error(`Failed to fetch blob: ${JSON.stringify(error)}`);
        }
    }
}
