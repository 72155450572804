import { OfficeWrapper } from "./OfficeWrapper";
import { Logger } from "./Logger";
import { GraphRequestWrapper } from "./GraphRequestWrapper";
import {
    MsGraphGetMessageResponse,
    MsGraphGetMessagesResponse,
    MsGraphMessageItem,
} from "../models/MsGraphMessageResponse";
import { EmailListItem, EmailListResponse } from "../models/EmailListResponse";
import { MsGraphGetFolderResponse } from "../models/MsGraphGetFolderResponse";
import { ExpiredSessionError } from "../models/ExpiredSessionError";
import { NewMessageFormParams } from "../models/NewMessageFormParams";
import {
    MsGraphGetEmailAttachmentResponse,
    MsGraphItemTypeAttachmentExpandedDetailsResponse,
} from "../models/MsGraphGetEmailAttachmentResponse";

export class MsGraphApiService {
    private readonly graphUrl = "https://graph.microsoft.com/v1.0";
    private readonly retryAfterHeaderKey = "Retry-After";
    private readonly defaultRetryTimeInSeconds = 1;
    private readonly maxRetryAttempts = 5;
    private readonly messageListPageSize = 50;

    constructor(
        private officeWrapper: OfficeWrapper,
        private graphRequestWrapper: GraphRequestWrapper,
        private logger: Logger
    ) {}

    async getCurrentFolderId(): Promise<string> {
        const messageDetails = await this.getCurrentMessageResponseData();
        return messageDetails.parentFolderId;
    }

    async getFolderName(folderId: string): Promise<string> {
        const folderResponseData = await this.getFolderResponseData(folderId);
        this.logger.info(`getFolderName with ${folderId} returns ${folderResponseData.displayName}`);

        return folderResponseData.displayName;
    }

    async getCurrentMessageAsEmailListItem(): Promise<EmailListItem> {
        const messageDetails = await this.getCurrentMessageResponseData();
        const currentMessageAsEmailListItem = this.convertMsGraphMessageItemToEmailListItem(messageDetails);
        this.logger.info(`currentMessageAsEmailListItem: ${JSON.stringify(currentMessageAsEmailListItem, null, 2)}`);

        return currentMessageAsEmailListItem;
    }

    async getFolderEmails(
        folderId: string,
        offsetToken: string | null,
        searchValue: string | null
    ): Promise<EmailListResponse> {
        const emailsGraphResponse = await this.getFolderEmailsResponseData(folderId, offsetToken, searchValue);
        const emailsGraphResponseMapped: EmailListResponse = {
            items: emailsGraphResponse.value.map((email) => this.convertMsGraphMessageItemToEmailListItem(email)),
            paging: {
                offsetToken: emailsGraphResponse["@odata.nextLink"],
            },
        };
        this.logger.info(`getFolderEmails: emailsGraphResponseMapped --> ${JSON.stringify(emailsGraphResponseMapped)}`);
        return emailsGraphResponseMapped;
    }

    async moveMessage(messageId: string): Promise<unknown> {
        const url = `${this.graphUrl}/me/messages/${this.convertIdForGraphApi(messageId)}/move`;
        this.logger.info(`Call to graph move ${messageId} to deleted folder`);

        return this.makeRequestWithRetry(() =>
            this.graphRequestWrapper.post(
                url,
                undefined,
                { Accept: "application/json", "Content-Type": "application/json" },
                JSON.stringify({ destinationId: "deleteditems" })
            )
        );
    }

    async createNewDraft(body: NewMessageFormParams): Promise<string> {
        const url = `${this.graphUrl}/me/messages`;
        this.logger.info("Call to graph API to create draft");

        const ccEmailAddresses = body.ccRecipients.map((recipient) => ({
            emailAddress: { address: recipient.emailAddress },
        }));

        const emailAddresses = body.toRecipients.map((recipient) => ({
            emailAddress: { address: recipient.emailAddress },
        }));

        const res = await this.makeRequestWithRetry(() =>
            this.graphRequestWrapper.post(
                url,
                undefined,
                { Accept: "application/json", "Content-Type": "application/json" },
                JSON.stringify({
                    body: { contentType: "HTML", content: body.htmlBody },
                    subject: body.subject,
                    toRecipients: emailAddresses,
                    ccRecipients: ccEmailAddresses,
                    attachments: body.attachments,
                })
            )
        );

        return res.id;
    }

    async getAttachment(messageId: string, attachmentId: string): Promise<MsGraphGetEmailAttachmentResponse> {
        this.logger.info("Call to graph API to get attachment");

        const graphApiMessageId = this.convertIdForGraphApi(messageId);
        const graphApiAttachmentId = this.convertIdForGraphApi(attachmentId);

        const url = `${this.graphUrl}/me/messages/${graphApiMessageId}/attachments/${graphApiAttachmentId}`;

        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    async getAttachmentRawValue(messageId: string, attachmentId: string): Promise<string> {
        this.logger.info("Call to graph API to get attachment raw value");

        const graphApiMessageId = this.convertIdForGraphApi(messageId);
        const graphApiAttachmentId = this.convertIdForGraphApi(attachmentId);

        const url = `${this.graphUrl}/me/messages/${graphApiMessageId}/attachments/${graphApiAttachmentId}/$value`;

        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    async getAttachmentItemTypeExpandedDetails(
        messageId: string,
        attachmentId: string
    ): Promise<MsGraphItemTypeAttachmentExpandedDetailsResponse> {
        this.logger.info("Retrieving email attachment expanded details from Outlook REST API");

        const graphApiMessageId = this.convertIdForGraphApi(messageId);
        const graphApiAttachmentId = this.convertIdForGraphApi(attachmentId);
        const url = `${this.graphUrl}/me/messages/${graphApiMessageId}/attachments/${graphApiAttachmentId}?$expand=Microsoft.graph.ItemAttachment/Item`;

        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    private async getFolderEmailsResponseData(
        folderId: string,
        offsetToken: string | null,
        searchValue: string | null
    ): Promise<MsGraphGetMessagesResponse> {
        this.logger.info(`Retrieving folder emails from graph API for folder: ${folderId}`);
        let updatedSearchValue = searchValue;
        if (searchValue) {
            updatedSearchValue = searchValue.replace('"', '\\"');
        }

        const searchFilter = !!searchValue ? `&$search="${updatedSearchValue}"` : "";
        let url = `${this.graphUrl}/me/mailFolders/${this.convertIdForGraphApi(folderId)}/messages?$top=${
            this.messageListPageSize
        }${searchFilter}`;
        if (!!offsetToken) {
            url = offsetToken;
        }
        const response = this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));

        this.logger.info(`getFolderEmailsResponseData: response --> ${JSON.stringify(response)}`);
        return response;
    }

    private isFiled(categories: string[]): boolean {
        return categories?.length > 0;
    }

    private async getCurrentMessageResponseData(): Promise<MsGraphGetMessageResponse> {
        this.logger.info("Retrieving current message data from graph API");
        const itemId = this.officeWrapper.getCurrentMessageId();
        const url = `${this.graphUrl}/me/messages/${this.convertIdForGraphApi(itemId)}`;
        return this.getValidMessageResponseWithRetry(() => this.graphRequestWrapper.get(url));
    }

    private async getFolderResponseData(folderId: string): Promise<MsGraphGetFolderResponse> {
        this.logger.info("Retrieving folder emails from graph API");
        const url = `${this.graphUrl}/me/mailFolders/${this.convertIdForGraphApi(folderId)}`;
        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    private convertIdForGraphApi(id: string) {
        return id.replace(/\//g, "-").replace(/\+/g, "_");
    }

    private convertMsGraphMessageItemToEmailListItem(email: MsGraphMessageItem): EmailListItem {
        return {
            id: email.id,
            sentDateTime: email.sentDateTime,
            hasAttachments: email.hasAttachments,
            isFiled: this.isFiled(email.categories),
            subject: email.subject,
            bodyPreview: email.bodyPreview,
            sender: {
                address: email.sender.emailAddress.address,
                name: email.sender.emailAddress.name,
            },
            from: {
                address: email.from.emailAddress.address,
                name: email.from.emailAddress.name,
            },
            categories: email.categories,
        };
    }

    async getValidMessageResponseWithRetry(
        request: () => Promise<MsGraphGetMessageResponse>,
        attemptCount: number = 1
    ): Promise<MsGraphGetMessageResponse> {
        try {
            const response = await request();
            if (!response.parentFolderId) {
                throw new Error("Unable to retrieve parent folder id");
            } else {
                return response;
            }
        } catch (error) {
            if (attemptCount === this.maxRetryAttempts) {
                this.logger.error(
                    `Max retry attempts (${this.maxRetryAttempts}) reached for request.\n${JSON.stringify(error)}`
                );
                throw error;
            }

            if ((error as any).status && (error as any).status === 429) {
                const retryAfter =
                    ((error as any).getResponseHeader(this.retryAfterHeaderKey) || this.defaultRetryTimeInSeconds) *
                    1000;
                this.logger.error(
                    `The request was throttled by the office api on attempt (${attemptCount}). retrying after ${retryAfter}ms... `
                );
                await new Promise((resolve) => {
                    setTimeout(resolve, retryAfter);
                });
                return this.getValidMessageResponseWithRetry(request, attemptCount + 1);
            }

            return this.getValidMessageResponseWithRetry(request, attemptCount + 1);
        }
    }

    async makeRequestWithRetry(request: () => Promise<any>, attemptCount: number = 1): Promise<any> {
        this.logger.info(`makeRequestWithRetry attemptCount -> ${attemptCount}`);
        try {
            return await request();
        } catch (error) {
            if (attemptCount === this.maxRetryAttempts) {
                this.logger.error(
                    `Max retry attempts (${this.maxRetryAttempts}) reached for request.\n${JSON.stringify(error)}`
                );
                throw error;
            }

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.logger.error(`session expired, request attempt #${attemptCount}`);
                return this.makeRequestWithRetry(request, attemptCount + 1);
            }

            if ((error as any).status && (error as any).status === 429) {
                const retryAfter =
                    ((error as any).getResponseHeader(this.retryAfterHeaderKey) || this.defaultRetryTimeInSeconds) *
                    1000;
                this.logger.error(
                    `The request was throttled by the office api on attempt (${attemptCount}). retrying after ${retryAfter}ms... `
                );
                await new Promise((resolve) => {
                    setTimeout(resolve, retryAfter);
                });
                return this.makeRequestWithRetry(request, attemptCount + 1);
            }
        }
    }
}
