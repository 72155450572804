import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./ProjectEmailsViewerAttachmentsComponent.less";
import { Email } from "@newforma/platform-client-api-sdk";
import { Icon } from "office-ui-fabric-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppService } from "../../../../services/AppServiceProvider";
import { ExtendedEmailDetails } from "../../../../models/ProjectEmailViewer";

export interface ProjectEmailsViewerAttachmentsComponent extends LocalizeContextProps {
    email: ExtendedEmailDetails;
    getEmailAttachment: (emailId: string, attachment: Email.EmailAttachmentDetails) => Promise<void>;
    isExpanded: boolean;
    toggleParticipantsExpanded: (messageId: string) => void;
}

function ProjectEmailsViewerAttachmentsComponent({
    email,
    getEmailAttachment,
    isExpanded,
    toggleParticipantsExpanded,
}: ProjectEmailsViewerAttachmentsComponent): JSX.Element {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [hiddenItemCount, setHiddenItemCount] = useState(0);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const itemsRef = useRef<(HTMLButtonElement | null)[]>([]);
    const [containerWidth, setContainerWidth] = useState(0);
    const {
        services: { windowWrapper },
    } = useAppService();
    const itemGap = 20;

    const checkOverflow = () => {
        if (containerRef.current && itemsRef.current.length > 0) {
            const container = containerRef.current;
            let totalWidth = 0;
            let itemsInFirstRow = 0;

            for (const item of itemsRef.current) {
                const itemWidth = item?.offsetWidth || 0;
                totalWidth += itemWidth + itemGap;
                if (totalWidth <= container.offsetWidth + itemGap) {
                    itemsInFirstRow++;
                } else {
                    break;
                }
            }
            const hiddenCount = email.attachments?.length ? email?.attachments.length - itemsInFirstRow : 0;
            setHiddenItemCount(hiddenCount > 0 ? hiddenCount : 0);
            setTimeout(() => setIsOverflowing(container.scrollHeight > container.offsetHeight), 100);
        }
    };

    const resizeHandler = useCallback(() => {
        setContainerWidth(containerRef.current?.offsetWidth || 0);
    }, []);

    useEffect(() => {
        checkOverflow();
        windowWrapper.addEventListener("resize", resizeHandler);
        return () => windowWrapper.removeEventListener("resize", resizeHandler);
    }, [containerWidth, email.attachments, resizeHandler]);

    const getEmailAttachmentClick = async (attachment: Email.EmailAttachmentDetails) => {
        await getEmailAttachment(email?.messageId || "", attachment);
    };

    const getIconName = (contentType: string) => {
        switch (contentType) {
            case "application/pdf":
                return "pdf";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return "worddocument";
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "exceldocument";
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                return "powerpointdocument";
            case "application/x-zip-compressed":
                return "zipfolder";
            case "text/html":
            case "application/octet-stream":
                return "filehtml";
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
            case "image/gif":
            case "image/bmp":
                return "fileimage";
            default:
                return "textdocument";
        }
    };

    const handleToggleAttachments = () => {
        toggleParticipantsExpanded(email.messageId);
        checkOverflow();
    };

    return (
        <>
            {email.attachments?.length ? (
                <div className="newforma-attachments">
                    <div className="newforma-attachmentsAttachIconContainer">
                        <Icon iconName="attach" className="newforma-attachmentsAttachIcon" />
                    </div>
                    <div
                        className="newforma-attachmentsFilesContainer"
                        ref={containerRef}
                        style={{
                            height: isExpanded ? "auto" : "40px",
                        }}
                        data-testid="attachments-files-container"
                    >
                        {email?.attachments?.map((attachment, index) => (
                            <button
                                key={attachment.id}
                                onClick={() => getEmailAttachmentClick(attachment)}
                                ref={(el) => (itemsRef.current[index] = el)}
                                data-testid="download-attachment-button"
                            >
                                <Icon iconName={getIconName(attachment.contentType)} /> {attachment.name}
                            </button>
                        ))}
                    </div>
                    {hiddenItemCount ? (
                        <div
                            className="newforma-attachmentsExpandButton"
                            data-testid="attachments-toggle-button"
                            onClick={handleToggleAttachments}
                        >
                            {isOverflowing && !isExpanded ? (
                                <>
                                    <span>+ {hiddenItemCount}</span>{" "}
                                    <Icon iconName="chevrondownsmall" data-testid="attachments-expand-icon" />
                                </>
                            ) : (
                                <Icon iconName="chevronupsmall" data-testid="attachments-collapse-icon" />
                            )}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
}

export default withLocalize(ProjectEmailsViewerAttachmentsComponent);
